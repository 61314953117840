import React from 'react';
import { SortBy } from 'react-instantsearch';
import { getSortOptions } from './sortHelpers';

const VirtualSortBy = (props) => {
  const { indexName, defaultQuoteQty, pricingAlgorithm, algoliaIndexPrefix} = props;

  return <SortBy
    style={{display: "none"}}
    items={getSortOptions(indexName, defaultQuoteQty, pricingAlgorithm, algoliaIndexPrefix)}
  />
}

export default VirtualSortBy;
