import React, {useEffect, useState} from 'react';
import {
  getSortLabelFromValueMap,
  getSimplifiedSortOptions as getSortOptions
} from './sortHelpers';
import {
  useSortBy,
  useRefinementList,
  useCurrentRefinements
} from 'react-instantsearch';
import {trackSortByFilterSelectEvent} from '../../utils/metrics/listingsMetrics';

const getOrientation = () => {
  if (window.screen.orientation) {
    return window.screen.orientation.angle === '0' ? 'portrait' : 'landscape';
  } else {
    return window.orientation === '0' ? 'portrait' : 'landscape';
  }
};

const Sort = (props) => {
  const initialOrientation = getOrientation();
  const [orientation, setOrientation] = useState(initialOrientation);
  const {indexName, defaultQuoteQty, pricingAlgorithm, algoliaIndexPrefix, showOptions} =
    props;
  const sortOptions = getSortOptions(
    indexName,
    defaultQuoteQty,
    pricingAlgorithm
  );
  const valueToLabelMap = getSortLabelFromValueMap(
    indexName,
    defaultQuoteQty,
    pricingAlgorithm,
    algoliaIndexPrefix
  );
  const sortByApi = useSortBy({
    items: sortOptions
  });

  const {refine} = useRefinementList({
    attribute: 'sort_by',
    limit: 99,
    showMore: false,
    showMoreLimit: 100,
    values: undefined,
    sortBy: ['count']
  });

  const {items, refine: clearRefine} = useCurrentRefinements({});

  const sortOptionGroupClassname = (option) => {
    const sortedRefinements = items?.filter(
      (item) => item.attribute == 'sort_by'
    );
    const simplifiedSortRefinement =
      sortedRefinements && sortedRefinements.length > 0
        ? sortedRefinements[0]?.refinements[0].value
        : null;
    if (simplifiedSortRefinement === option.value) {
      return 'sb-Form-group sb-Form-group--checked';
    } else {
      return 'sb-Form-group';
    }
  };

  const applySort = (option) => {
    const sortedRefinements = items?.filter(
      (item) => item.attribute == 'sort_by'
    );
    sortedRefinements &&
      sortedRefinements.length > 0 &&
      clearRefine(sortedRefinements[0]?.refinements[0]); //make sure we're only clear sort refinements here, depends on only have 1 sort refinement active
    trackSortByFilterSelectEvent(option.analyticsTag);
    refine(option.value);
  };

  useEffect(() => {
    if (showOptions) {
      if (window.screen.availWidth < 700) {
        document.documentElement.style.overflow = 'hidden';
      } else {
        document.documentElement.style.overflow = 'auto';
      }
    } else {
      document.documentElement.style.overflow = 'auto';
    }
  }, [showOptions, orientation]);

  useEffect(() => {
    const handleResize = () => {
      const orientation = getOrientation();
      setOrientation(orientation);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const Options = () => {
    return (
      <div className="pc-AlgoliaSortDropdown">
        <header className="pc-OverlayDropdown-header">
          Sort By
          <span className="pc-OverlayDropdown-headerClose"></span>
        </header>
        <div className="pc-OverlayDropdown-body">
          <span className="pc-OverlayDropdown-label">
            Choose a sorting option:
          </span>
          <div className="sb-Form pc-Form">
            {sortByApi.options?.map((option) => (
              <div
                key={option.value}
                className={sortOptionGroupClassname(option)}
                onClick={() => applySort(option)}>
                <label className="sb-Form-radio">
                  <input type="radio" />
                  <a className="pc-OverlayDropdown-link">
                    <span className="sb-Form-indicator"></span>
                    {option.label}
                  </a>
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const getSortDisplayValue = () => {
    const simplifiedMapping = {
      none: 'Recommended',
      rating: 'Highest Rated',
      newest: 'Newest'
    };
    if (
      items.length > 0 &&
      items[0].refinements.length > 0 &&
      items[0].refinements[0].attribute === 'sort_by'
    ) {
      const val = items[0].refinements[0].value;
      if (['none', 'rating', 'newest'].indexOf(val) > -1) {
        return simplifiedMapping[val];
      } else {
        const arr = val?.split('-');
        const isAscending = arr[arr.length - 1].endsWith('asc');
        return isAscending ? 'Price: Low to High' : 'Price: High to Low';
      }
    } else {
      return 'Recommended';
    }
  };

  return (
    <div
      className="pc-ToolBar-sort algolia"
      onClick={() => props.onToggleShowOptions()}
      onMouseLeave={() => props.onRemoveShowOptions()}>
      <div className="pc-ToolBar-sortForm">
        <div className="pc-ToolBar-sortForm-content">
          <span>Sort By:</span>
          {'\u00A0'}
          <div className="pc-ToolBar-sortForm-value">
            {getSortDisplayValue()}
          </div>
        </div>
      </div>
      {showOptions && <Options />}
    </div>
  );
};

export default Sort;
