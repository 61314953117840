import React from 'react';
import { useRefinementList } from 'react-instantsearch';

const InternationalShipEligible = (props) => {
  const { internalCatalog, filterChangeHandler, ...algoliaProps } = props;
  const {items, refine} = useRefinementList(algoliaProps);

  const internationalShipEligibleToggleClicked = (event, item) => {
    const onOrOffStatus = !item.isRefined ? 'on' : 'off';
    CustomInk.Metrics.fireEvent(
      'product international ship eligible',
      'ordering items for international shipping',
      onOrOffStatus
    );
    refine(item.value);
  }

  return items.length < 2 ? '' : (
    <nav className="pc-FiltersGroup pc-FiltersGroup-international-ship-eligible is-Internal" style={{display: internalCatalog ? 'block': 'none'}} >
      <header className="pc-FiltersGroup-header expanded">International Shipping</header>
      <div className='pc-FiltersGroup-values'>
        <span id='pc-ToolBar-toggle-international-ship-eligible-label'>Ordering items for international shipping?</span>
        <div
          className='pc-FiltersGroup-international-ship-eligible-values'>
          <span>
            No
            {
              items.map((item) => {
                let hideItem = item.value === 'false';
                return (
                <React.Fragment key={`${item.value}} fragment`}>
                  <input
                    className='pc-ToolBar-toggle pc-ToolBar-toggle-international-ship-eligible'
                    key={item.value}
                    type="checkbox"
                    aria-labelledby='pc-ToolBar-toggle-international-ship-eligible-label'
                    checked={item.isRefined}
                    onChange={() => {}}
                    style={hideItem ? {display: 'none'} : {}}
                  />
                  <div
                    className='pc-ToolBar-toggle-button'
                    key={`${item.value}} toggle`}
                    onClick={event => internationalShipEligibleToggleClicked(event, item)}
                    style={hideItem ? {display: 'none'} : {}}
                  />
                </React.Fragment>
                )
              })
            }
            Yes
          </span>
        </div>
      </div>
    </nav>
  )
}

export default InternationalShipEligible;
