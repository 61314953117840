import React, { useState } from 'react';
import { useRefinementList } from 'react-instantsearch';

const InternalFilterGroup = (props) => {
  const { internalCatalog, filterChangeHandler, ...algoliaProps } = props;
  const { attribute } =  algoliaProps;
  const {items, refine, toggleShowMore, canToggleShowMore} = useRefinementList(algoliaProps);
  const [displayShowMore, setDisplayShowMore] = useState(true);

  const internalFilterHeaderMap =  {
    'broad_category_names' :  'Broad Category',
    'sub_category_names': 'Subcategories',
    'desired_quantity': 'Desired Quantity',
    'search_data_material': 'Material',
    'min_order_qty': 'Order Size',
    'no_minimum': 'No Minimum',
    'rush_turn_time': 'Rush Turn Time',
    'sizes': 'Sizes',
    'decoration_method': 'Decoration',
    'turn_time': 'Standard Turn Time',
    'weight': 'Weight'
  };

  const filterGroupHeader = (header) => {
    let titleAttribute = header;
    let title = titleAttribute.replace(/^fg\-/gi, '');
    let filterGroupSplit = title.split('-');
    let titleArray = filterGroupSplit.map(fg => fg[0].toUpperCase() + fg.slice(1));
    return titleArray.join(' ');
  }

  const labelForAttribute = `fs-${filterGroupHeader(attribute)}-`;

  const applyFilter = (event, item) => {
    event.preventDefault();
    refine(item.value);
  }

  const displayShowMoreElement = () => {
    setDisplayShowMore((prevState) => !prevState);
  }

  const handleShowOption = () => {
    toggleShowMore();
  }

  return items.length < 1 ? '' : (
    <nav className="pc-FiltersGroup is-Internal" style={{display: internalCatalog ? 'block': 'none'}}>
      <header className='pc-FiltersGroup-header expanded' onClick={() => displayShowMoreElement()}>{internalFilterHeaderMap[attribute] || attribute}</header>
      <div className='pc-FiltersGroup-values'>
        {
          items.map((item) => {
            return (
              <label
                htmlFor={labelForAttribute + item.highlighted}
                key={item.highlighted}
                className='sb-Form-checkbox pc-FiltersGroup-value'
              >
                <input
                  type="checkbox"
                  id={labelForAttribute + item.highlighted}
                  value={item.highlighted}
                  checked={item.isRefined} // when checked -> true (filter is applied)
                  onChange={() => {}}
                ></input>
                <a className='pc-FiltersGroup-link'
                  onClick={(event) => applyFilter(event, item)}
                >
                  <span className="sb-Form-indicator"></span>
                  {`${item.highlighted} (${item.count})`}
                </a>
              </label>
            )
          })
        }
        {(canToggleShowMore && displayShowMore) &&
          <a
            className='pc-FiltersGroup-toggle'
            onClick={handleShowOption}
          >
          Show {canToggleShowMore ? 'more' : 'fewer'}
          </a>
        }
      </div>
    </nav>
  )
}

export default InternalFilterGroup;
