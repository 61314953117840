import React, {useEffect, useState} from 'react';
import Autocomplete from '../components/Autocomplete';
import isShortcutToDesign from '../utils/cookies';
import {trackSortByFilterShowEvent} from '../utils/metrics/listingsMetrics';
import Sort from './sort/Sort';
import {
  AUTOCOMPLETE_FACETS,
  AUTOCOMPLETE_HITS_TO_SHOW
} from './utils/algolia_constants';
import {getDefaultQuoteQuantity} from './utils/defaultUnitPricing';
import {navPath} from './utils/route_utils';

function ListingsHeader(props) {
  const [showOptions, setShowOptions] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const {
    algoliaClient,
    algoliaIndexPrefix,
    categoryId,
    categoryName,
    defaultQuoteQty,
    filtersCount,
    hitsLength,
    indexName,
    isMobile,
    pricingAlgorithm,
    railsEnv,
    scroll,
    searchParams,
    searchQuery,
    siteWideSearch,
    width
  } = props;

  const searchNotFoundCategoryOptions = [
    {name: 'T-Shirts', path: navPath('/products/t-shirts/4', searchParams)},
    {name: 'All Products', path: navPath('/products', searchParams)},
    {
      name: 'Groups & Collections',
      path: navPath('/products/groups-collections/301', searchParams)
    },
    {
      name: 'Shop By Group Size',
      path: navPath('/products/shop-by-group-size/852', searchParams)
    },
    {
      name: 'Extended Sizes',
      path: navPath('/products/extended-sizes/723', searchParams)
    }
  ];

  const prefixedCategoryName = () => {
    if (!categoryName?.match(/Custom\s/i) && isShortcutToDesign()) {
      return `Custom ${categoryName}`;
    }
    return categoryName;
  };

  const toolBarTitle = () => {
    if (searchQuery && hitsLength === 0) {
      return 'All Products';
    }
    if (searchQuery && siteWideSearch && hitsLength !== 0) {
      return 'Search Results';
    }
    return categoryName ? prefixedCategoryName() : 'All Products';
  };

  const handleToggleShowOptions = () => {
    trackSortByFilterShowEvent(!showOptions);
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    if (isMobile) {
      const isBackgrounded =
        document.getElementsByClassName('MuiModal-root').length > 0;
      setIsSticky((scroll || isBackgrounded) && !showOptions);
    } else {
      setIsSticky(scroll);
    }
  }, [scroll, showOptions]);

  return (
    <>
      {isSticky && <div style={{height: isMobile ? '70px' : '76px'}} />}
      <div className={`pc-ToolBar ${isSticky ? 'sb-Wrapper is-sticky' : ''}`}>
        <div className="pc-ToolBar-section">
          {toolBarTitle() && (
            <div className="pc-ToolBar-header">
              <h1 itemProp="name" className="pc-ToolBar-header-title">
                {toolBarTitle()}
              </h1>
            </div>
          )}
          <Autocomplete
            facets={AUTOCOMPLETE_FACETS}
            hitsToShow={AUTOCOMPLETE_HITS_TO_SHOW}
            railsEnv={railsEnv}
            algoliaClient={algoliaClient}
          />
        </div>
        {hitsLength !== 0 && (
          <div className="pc-ToolBar-section">
            <div className="pc-ToolBar-filterNav activeNav">
              <div className="pc-ToolBar-filterNav-area">
                <span>
                  Filters
                  <span style={{display: filtersCount > 0 ? 'unset' : 'none'}}>
                    {` (${filtersCount})`}
                  </span>
                </span>
              </div>
            </div>
            <Sort
              indexName={indexName}
              defaultQuoteQty={getDefaultQuoteQuantity(
                categoryId,
                defaultQuoteQty
              )}
              pricingAlgorithm={pricingAlgorithm}
              algoliaIndexPrefix={algoliaIndexPrefix}
              width={width}
              onToggleShowOptions={handleToggleShowOptions}
              onRemoveShowOptions={() => setShowOptions(false)}
              showOptions={showOptions}
            />
          </div>
        )}
        {hitsLength === 0 && searchQuery && (
          <div className="pc-ToolBar-section">
            <div className="pc-ToolBar-shopBy">
              <div className="pc-ToolBar-shopBy-label">
                Shop By:
                <div className="pc-ToolBar-shopBy-value">All Products</div>
                <div className="pc-ToolBar-shopBy-caret" />
              </div>
              <div className="pc-OverlayDropdown" style={{display: 'none'}}>
                <header className="pc-OverlayDropdown-header">
                  Shop By
                  <span className="pc-OverlayDropdown-headerClose" />
                </header>
                <div className="pc-OverlayDropdown-body">
                  <span className="pc-OverlayDropdown-label">
                    Choose a shopping option:
                  </span>
                  <div className="sb-Form pc-Form">
                    {searchNotFoundCategoryOptions.map((catOption) => {
                      return (
                        <div className="sb-Form-group" key={catOption.name}>
                          <label className="sb-Form-radio">
                            <a
                              className="pc-OverlayDropdown-link pc-ToolBar-shopBy-link"
                              id={`pc_toolbar_shopby_link_${catOption.name.toLowerCase()}`}
                              href={catOption.path}>
                              {catOption.name}
                            </a>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ListingsHeader;
