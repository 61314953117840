import React from 'react';

export default function FacetResult({ hit, components, showCounts }) {
  return (
    <div className="cat-option">
      <div className="aa-ItemTitle">
        <components.Highlight hit={hit} attribute="label" tagName="mark" />
        {showCounts && (
          <span className="badge ms-2">{hit.count}</span>
        )}
      </div>
    </div>
  )
};
