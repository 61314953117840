import {useState} from 'react';

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const saveScrollPosition = () => {
    setScrollPosition(window.scrollY);
    return window.scrollY;
  };
  const restoreScrollPosition = () => window.scrollTo(0, scrollPosition);

  return [saveScrollPosition, restoreScrollPosition];
};

export default useScrollPosition;
