import React from 'react';
import {Box} from '@customink/pigment-react';

const ComparisonColumn = ({children, rightBorder = false}) => {
  return (
    <Box
      component="td"
      sx={{
        padding: '0.5rem 1rem',
        maxWidth: '17rem',
        textAlign: 'left',
        verticalAlign: 'top',
        color: '#444',
        lineHeight: '1.5',
        ...(rightBorder && {borderRight: '1px solid #222'})
      }}
    >
      {children}
    </Box>
  );
};

export default ComparisonColumn;
