import React from 'react';
import { useMenu } from 'react-instantsearch';

const Specialty = (props) => {
  const { internalCatalog, filterChangeHandler, ...algoliaProps } = props;
  const {items, refine} = useMenu(algoliaProps);

  const applySpecialtyOption = (value) => {
    const onOrOffStatus = value === 'Both' ? 'off' : 'on';
    CustomInk.Metrics.fireEvent(
      'event',
      'product specialty',
      onOrOffStatus
    );
    if (onOrOffStatus === 'on') {
      refine(value)
    } else {
      refine();
    }
  }

  return items.length < 1 ? '' : (
    <nav className='pc-FiltersGroup pc-FiltersGroup-specialty is-Internal' style={{display: internalCatalog ? 'block': 'none'}}>
      <header className='pc-FiltersGroup-header expanded'>Specialty</header>
      <div className='pc-FiltersGroup-values'>
        {
          items.map((item) => {
            return(
              <div className='sb-Form-group' key={`specialty_${item.value}`}>
                <label className='sb-Form-checkbox'>
                  <input
                    className='pc-ToolBar-toggle pc-ToolBar-toggle-specialty'
                    type='radio'
                    name='specialty[]'
                    id={`specialty_${item.value}`}
                    checked={item.isRefined}
                    onChange={() => applySpecialtyOption(item.value)}
                  />
                  <span className="sb-Form-indicator"></span>
                  {item.value === 'true' ? 'Specialty Only' : 'Non-Specialty Only'}
                </label>
              </div>
            )
          })
        }
        <div className='sb-Form-group' key={`specialty_both`}>
          <label className='sb-Form-checkbox'>
            <input
              className='pc-ToolBar-toggle pc-ToolBar-toggle-specialty'
              type='radio'
              name='specialty[]'
              id={`specialty_both`}
              checked={items.every((item) => item.isRefined === false)}
              onChange={() => applySpecialtyOption('')}
              value={'Both'}
            />
            <span className="sb-Form-indicator"></span>
            Both
          </label>
        </div>
      </div>
    </nav>
  )
}

export default Specialty;
