import { MENU_KEYS, INTERNAL_FILTERS, INTERNAL_QC_FORM_INPUT_PARAMS, NUMERIC_MENU_KEYS } from "./algolia_constants";
import { convertParamsToUrl, getQueryParams, percentReplacedElement } from "./route_utils";

const swapColorsToMinQtyColors = (refinementList, setUiState, uiState, algoliaIndexName) => {
  let { 'colors.simple_names': colorRefinements } = refinementList;
  delete refinementList['colors.simple_names'];
  refinementList['min_qty_colors.simple_names'] = colorRefinements;
  setUiState({
    ...uiState,
    [algoliaIndexName]: {
      ...uiState[algoliaIndexName],
      refinementList: {
        ...refinementList
      }
    }
  })
}

const swapMinQtyColorsToColors = (refinementList, setUiState, uiState, algoliaIndexName) => {
  let { 'min_qty_colors.simple_names': colorRefinements } = refinementList;
  delete refinementList['min_qty_colors.simple_names'];
  refinementList['colors.simple_names'] = colorRefinements;
  setUiState({
    ...uiState,
    [algoliaIndexName]: {
      ...uiState[algoliaIndexName],
      refinementList: {
        ...refinementList,
      }
    }
  })
}

export const instantSearchRouterHistoryUrl = (routeState, location, searchToRefinementListMap, currentUserInternal) => {
  const url = new URL(location.href);
  let algoliaUrl = `${url.origin}${url.pathname}`;
  let categoryFilterKeys = Object.keys(searchToRefinementListMap);
  if (currentUserInternal) {
    categoryFilterKeys = [...categoryFilterKeys, ...INTERNAL_FILTERS, 'specialty'];
  }
  const urlQueryParams = getQueryParams(routeState, categoryFilterKeys, url.search);
  algoliaUrl = convertParamsToUrl(urlQueryParams, algoliaUrl);
  return algoliaUrl;
};

export const mapStateToRoute = (uiState, algoliaIndexName, algoliaToCatalogSort, refinementListToSearchMap, algoliaToCatalogSortSimplified) => {
  const indexUiState = uiState[algoliaIndexName];
  let refinementList = indexUiState.refinementList || {};
  let menu = indexUiState.menu;
  let numericMenu = indexUiState.numericMenu;
  let routeAttributes = {};
  routeAttributes['page'] = indexUiState.page;
  routeAttributes['keyword'] = indexUiState.query;

  for (const key in refinementList) {
    if (Object.hasOwnProperty.call(refinementList, key)) {
      const element = refinementList[key];
      const keyName = refinementListToSearchMap[key]  || key;
      if(keyName === 'min_qty') {
        routeAttributes[keyName] = [1];
      } else if (keyName === 'sort_by') {
        routeAttributes['sort_by'] = algoliaToCatalogSortSimplified[element[0]]
      } else {
        routeAttributes[keyName] =
          Array.isArray(element) ? element.map((el) => el.replace(/%20/g, "+")) : element.replace(/%20/g, "+");
      }
    }
  }
  for (const key in menu) {
    if (Object.hasOwnProperty.call(menu, key)) {
      const element = menu[key];
      const keyName = refinementListToSearchMap[key]  || key;
      routeAttributes[keyName] =
        Array.isArray(element) ? element.map((el) => el.replace(/%20/g, "+")) : element.replace(/%20/g, "+");
    }
  }
  for (const key in numericMenu) {
    if (Object.hasOwnProperty.call(numericMenu, key)) {
      const element = numericMenu[key];
      const keyName = refinementListToSearchMap[key]  || key;
      routeAttributes[keyName] =
        Array.isArray(element) ? element.map((el) => el.replace(/%20/g, "+")) : element.replace(/%20/g, "+");
    }
  }
  return routeAttributes;
};

export const mapRouteToState = (routeState, algoliaIndexName, catalogToAlgoliaSort, searchToRefinementListMap, catalogToAlgoliaSortSimplified) => {
  let stateAttributes = {};
  let refinementListObjectKey = 'refinementList';
  let menuListObjectKey = 'menu';
  let numericMenuListObjectKey = 'numericMenu';
  stateAttributes[algoliaIndexName] = {};
  stateAttributes[algoliaIndexName]['page'] = routeState.page;
  stateAttributes[algoliaIndexName][refinementListObjectKey] = {};
  stateAttributes[algoliaIndexName][menuListObjectKey] = {};
  stateAttributes[algoliaIndexName][numericMenuListObjectKey] = {};
  stateAttributes[algoliaIndexName]['query'] = routeState.keyword;
  
  if(!Object.hasOwnProperty.call(routeState, 'sort_by')) {
    stateAttributes[algoliaIndexName][refinementListObjectKey]['sort_by'] = ['none']
  }

  for (const key in routeState) {
    if (INTERNAL_QC_FORM_INPUT_PARAMS.filter(inputParam => inputParam !== 'min_order_qty').includes(key)) continue;
    if (Object.hasOwnProperty.call(routeState, key)) {
      if (MENU_KEYS.includes(key)) {
        let element = routeState[key];
        let menuAttribute = key;
        stateAttributes[algoliaIndexName][menuListObjectKey][menuAttribute] = element;
      } else if (NUMERIC_MENU_KEYS.includes(key)) {
        let element = routeState[key];
        let menuAttribute = key;
        stateAttributes[algoliaIndexName][numericMenuListObjectKey][menuAttribute] = element;
      } else {
        let element = routeState[key];
        let refinementAttribute = searchToRefinementListMap[key] || key;
        element = percentReplacedElement(element);
        const elementAttribute =
          Array.isArray(element) ? element.map(decodeURIComponent) : decodeURIComponent(element);
        if (key === 'min_qty') {
          stateAttributes[algoliaIndexName][refinementListObjectKey][refinementAttribute] = [true];
        } else if (key === 'sort_by') {
          const algorithm = catalogToAlgoliaSortSimplified[routeState.sort_by]
          stateAttributes[algoliaIndexName][refinementListObjectKey]['sort_by'] = [algorithm]
        } else if (key === 'compatible_type') {
          stateAttributes[algoliaIndexName][refinementListObjectKey][refinementAttribute] = [routeState[key]]
        } else {
          stateAttributes[algoliaIndexName][refinementListObjectKey][refinementAttribute] = elementAttribute;
        }
      }
    }
  }
  return stateAttributes;
};

const displayColorFilter = () => {
  const colorFilter = document.querySelector('.pc-FiltersGroup--colors.colors');
  const minQtyColorFilter = document.querySelector('.pc-FiltersGroup--colors.min_qty_colors');
  if (colorFilter && minQtyColorFilter) {
    colorFilter.style.display = 'flex';
    minQtyColorFilter.style.display = 'none';
  }

}
const displayMinQtyColorFilter = () => {
  const colorFilter = document.querySelector('.pc-FiltersGroup--colors.colors');
  const minQtyColorFilter = document.querySelector('.pc-FiltersGroup--colors.min_qty_colors');
  if (colorFilter && minQtyColorFilter) {
    colorFilter.style.display = 'none';
    minQtyColorFilter.style.display = 'flex';
  }
}

export const instantSearchOnStateChange = (uiState, setUiState, algoliaIndexName) =>  {
  const indexUiState = uiState[algoliaIndexName];
  let refinementList = indexUiState.refinementList;
  if (refinementList?.hasOwnProperty('has_singles_enabled_colors') && refinementList?.hasOwnProperty('colors.simple_names')) {
    swapColorsToMinQtyColors(refinementList, setUiState, uiState, algoliaIndexName);
    displayMinQtyColorFilter();
    return;
  } else if (!refinementList?.hasOwnProperty('has_singles_enabled_colors') && refinementList?.hasOwnProperty('min_qty_colors.simple_names')) {
    swapMinQtyColorsToColors(refinementList, setUiState, uiState, algoliaIndexName);
    displayColorFilter();
    return;
  }
  if (refinementList?.hasOwnProperty('has_singles_enabled_colors')) {
    displayMinQtyColorFilter();
  }
  if (!refinementList?.hasOwnProperty('has_singles_enabled_colors')) {
    displayColorFilter();
  }
  setUiState(uiState);
};
