import { useEffect, useState } from "react";
import $ from 'jquery';

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (width > 700 && $('.pc-Filters').is(':hidden')) {
        $('.pc-Filters').toggle();
      }
      if(width < 700 && $('.pc-Filters').is(':visible')) {
        $('.pc-Filters').toggle();
      }
      setWindowDimensions({ width, height });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export default useWindowDimensions;
