// We use this message to send style and color id to the lab when user browses product listing page
// so we cab open mini PDP modal in the lab
import postMessageForLab from '../../utils/labMessage';

export const publishStyleAndColorIdMessage = (styleId, colorId) => {
  const styleAndColor = {
    colorId,
    styleId,
    keepShopping: true
  };
  return postMessageForLab(styleAndColor);
};
