import {
  Box,
  Button,
  LabIconsUploadIcon,
  Typography
} from '@customink/pigment-react';
import React, {useEffect, useState} from 'react';
import useBreakpoint from '../../hooks/useBreakpoint';
import UploadModal from './UploadModal';

function UploadCard({customDesignPreview, setCustomDesignPreview}) {
  const [displayModal, setDisplayModal] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(() =>
    Boolean(ProductCatalog.LocalStorage.read('custom_design_preview'))
  );
  const [recentUploads, setRecentUploads] = useState(() => {
    const monthAgo = Date.now() - 30 * 24 * 60 * 60 * 1000;
    const recentDesignUploads =
      ProductCatalog.LocalStorage.read('recent_design_uploads') || [];
    return recentDesignUploads.filter(
      (upload) => upload?.uploadedAt >= monthAgo
    );
  });
  const isMobile = useBreakpoint() === 'sm';

  const fireMetricsEvent = (label) => {
    CustomInk.Metrics.pageViewTag(label);
  };
  const clearDesignPreview = () => {
    setCustomDesignPreview(null);
  };
  const handleAction = () => {
    if (fileUploaded) {
      fireMetricsEvent('catalog/upload/deleted');
      clearDesignPreview();
    } else {
      fireMetricsEvent('catalog/upload');
      setDisplayModal(true);
    }
  };

  useEffect(() => {
    ProductCatalog.LocalStorage.write(
      'recent_design_uploads',
      recentUploads || []
    );
  }, [recentUploads]);
  useEffect(() => {
    setFileUploaded(customDesignPreview !== null);
  }, [customDesignPreview]);

  return (
    <Box
      className="pc-Styles-uploadCard"
      sx={{
        background: 'rgba(0, 38, 255, 0.06)',
        borderRadius: '0.5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: isMobile ? '12px' : '16px',
        justifyContent: 'center',
        padding: isMobile ? '16px 24px' : '24px',
        textAlign: 'center',
        width: '100%'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: isMobile ? '2px' : '8px'
        }}>
        <Box
          className={`pc-Styles-uploadCard-text ${isMobile ? 'mobile' : 'full'}`}>
          {!fileUploaded && (
            <>
              <LabIconsUploadIcon sx={{fontSize: isMobile ? '32px' : '48px'}} />
              <br />
            </>
          )}
          <strong>
            {fileUploaded
              ? 'Design uploaded! '
              : `Have a design or logo ready? `}
          </strong>
        </Box>
        <Typography sx={{fontSize: '16px'}} textAlign="center">
          {fileUploaded
            ? 'Only you can see this preview.'
            : 'Upload it to preview on all products.'}
        </Typography>
      </Box>
      <Button
        variant="primary"
        size="medium"
        className="pc-Styles-uploadCard-action"
        onClick={() => {
          handleAction();
        }}>
        {fileUploaded ? 'Clear design' : 'Upload now'}
      </Button>
      <UploadModal
        className="pc-Styles-uploadCard-modal"
        displayModal={displayModal}
        recentUploads={recentUploads}
        setDisplayModal={setDisplayModal}
        setCustomDesignPreview={setCustomDesignPreview}
        setRecentUploads={setRecentUploads}
        fireMetricsEvent={fireMetricsEvent}
      />
    </Box>
  );
}

export default UploadCard;
