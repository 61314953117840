import {calculateQuote} from '../../../utils/quote';

const SPECIALITY_QUANTITIES = [100, 250, 500, 1000];
const DEFAULT_QUANTITIES = [25, 50, 100, 250];

export const calculateQuotes = (style) => {
  const quantities = style.specialty
    ? SPECIALITY_QUANTITIES
    : DEFAULT_QUANTITIES;
  const promises = quantities.map((qty) =>
    calculateQuote(style.product_id, qty, 1, 0)
      .then((quoteResult) => {
        const priceNum = parseFloat(quoteResult);
        const priceUsd = isNaN(priceNum)
          ? '{Price N/A}'
          : priceNum.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD'
            });
        return `${priceUsd} @ ${qty} each`;
      })
      .catch(() => {
        return ` @ ${qty} each`;
      })
  );

  return Promise.all(promises);
};
