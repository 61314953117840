import React from 'react';
import {Box, Stack, Typography} from '@customink/pigment-react';
import ComparisonColumn from './ComparisonColumn';

const ProductImageAndTitleColumn = ({style, rightBorder}) => {
  return (
    <ComparisonColumn rightBorder={rightBorder}>
      <Stack gap="1rem">
        <a href={style.productLink}>
          <Box
            component="img"
            sx={{height: '12rem'}}
            src={style.compareImageUrl}
            alt={style.name}
            className="pc-ProductCard-compareImage"
          />
        </a>
        <Typography
          component="a"
          href={style.productLink}
          sx={(theme) => ({
            fontSize: '1.375rem',
            color: theme.palette.grey['900'],
            '&:visited': {
              color: theme.palette.grey['900']
            }
          })}
        >
          {style.name}
        </Typography>
      </Stack>
    </ComparisonColumn>
  );
};

export default ProductImageAndTitleColumn;
