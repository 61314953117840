import React from 'react';
import PropTypes from 'prop-types';
import { useRefinementList } from 'react-instantsearch';

const IndividualShipEligible = (props) => {
  const { filterChangeHandler } = props;
  const algoliaProps = {...props};
  delete algoliaProps.filterChangeHandler;
  const {items, refine} = useRefinementList(algoliaProps);

  const individualShipEligibleClicked = (event, item) => {
    const onOrOffStatus = !item.isRefined ? 'on' : 'off';
    CustomInk.Metrics.fireEvent(
      'product individual ship eligible',
      'ordering items for individual shipping',
      onOrOffStatus
    );
    filterChangeHandler();
    window.dataLayer.push({
      event: 'catalog_filter',
      filter_type: 'fs-individual_ship_eligible',
      filter_value: item.value,
      filter_action: item.isRefined ? 'remove' : 'add'
    })
    refine(item.value);
  }

  return items.length < 2 ? '' : (
    <nav className="pc-FiltersGroup pc-FiltersGroup-individual-ship-eligible">
      <header className={`pc-FiltersGroup-header ${!props.isCollapsedFilter && 'expanded'}`}>Delivery Options</header>
      <div className='pc-FiltersGroup-values'>
        <span id='pc-ToolBar-toggle-individual-ship-eligible-label'>Available to ship to multiple addresses?</span>
        <div
          className='pc-FiltersGroup-individual-ship-eligible-values'>
          <span>
            No
            {
              items?.map((item) => {
                let hideItem = item.value === 'false';
                return (
                <React.Fragment key={`${item.value}} fragment`}>
                  <input
                    className='pc-ToolBar-toggle pc-ToolBar-toggle-individual-ship-eligible'
                    key={item.value}
                    type="checkbox"
                    aria-labelledby='pc-ToolBar-toggle-individual-ship-eligible-label'
                    checked={item.isRefined}
                    onChange={() => {}}
                    style={hideItem ? {display: 'none'} : {}}
                  />
                  <div
                    className='pc-ToolBar-toggle-button'
                    key={`${item.value}} toggle`}
                    data-insights-filter={`fs-individual_ship_eligible:${item.isRefined}`}
                    onClick={event => individualShipEligibleClicked(event, item)}
                    style={hideItem ? {display: 'none'} : {}}
                  />
                </React.Fragment>
                )
              })
            }
            Yes
          </span>
        </div>
      </div>
    </nav>
  )
}

IndividualShipEligible.propTypes = {
  attribute: PropTypes.string,
  defaultRefinement: PropTypes.bool,
  off: PropTypes.bool,
  on: PropTypes.bool
};

export default IndividualShipEligible;
