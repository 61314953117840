export const trackSortByFilterShowEvent = (isExpand) => {
  const label = isExpand ? 'expand' : 'collapse';
  CustomInk.Metrics.fireEvent('sort by filter', null, label);
};

export const trackEcoFriendlyHoverEvent = (label) => {
  CustomInk.Metrics.fireEvent(
    'product listing page',
    'ecofriendly hover',
    label
  );
};

export const trackFAQClickEvent = (categoryName, isExpanded) => {
  const eventLabel = isExpanded ? 'expand' : 'collapse';
  CustomInk.Metrics.fireEvent('FAQ', categoryName, eventLabel);
};

export const trackShopByFilterEvent = (isExpand) => {
  const eventLabel = isExpand ? 'expand' : 'collapse';
  CustomInk.Metrics.fireEvent('shop by filter', null, eventLabel);
};

export const trackSortByFilterSelectEvent = (value) => {
  window.dataLayer.push({
    event: 'product_sort',
    value
  });
};

export const trackNoResultsFoundWithColorLimitApplied = () => {
  CustomInk.Metrics.fireEvent(
    'helpful wcibmos',
    'color limit applied',
    'no results found'
  );
};
