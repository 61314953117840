import React from 'react';
import Rating from './../../algolia_listing_pages/product_card/Rating';

const getImageUrl = (hit) => {
  let url = hit.style_card_image_url;
  if (!url) url = `https://mms-images.out.customink.com/mms/images/catalog/colors/${hit.product_id}/views/alt/front_medium.jpg?digest=000000028&ixw=160`;
  return url;
}

export default function SimpleStyleResult({ hit, components }) {
  return (
    <a href={hit.url} className={`aa-ItemLink style-option status-${hit.status}`}>
      <div className="aa-Item-Content">
        <div style={{ margin: '0 auto 5px' }}>
          <img alt="Style Image" height="140" src={getImageUrl(hit)} />
        </div>
        <div className="aa-ItemTitle">
          <components.Highlight hit={hit} attribute="name" tagName="mark" />
          <div>
            { hit.rating_count > 0 &&
              <Rating
                ratingScore={hit.rating_score}
                ratingCount={hit.rating_count}
                name={hit.name}
                generalComments={hit.general_comments}
              />
            }
          </div>
        </div>
      </div>
    </a>
  )
};
