import React, {useEffect, useState} from 'react';
import Rating from '../../product_card/Rating';
import {Box, Stack} from '@customink/pigment-react';
import {transformStylesForComparison} from './utils/styleDataTransformation';
import ComparisonColumn from './ComparisonColumn';
import ProductImageAndTitleColumn from './ProductImageAndTitleColumn';
import ComparisonProductActionsColumn from './ComparisonProductActionsColumn';
import {calculateQuotes} from './utils/calculateQuotes';

const ProductComparison = ({stylesToCompare, removeStyleToCompare}) => {
  const [styleQuotes, setStyleQuotes] = useState({});

  useEffect(() => {
    stylesToCompare.map((style) => {
      calculateQuotes(style).then((res) => {
        setStyleQuotes((prevState) => ({
          ...prevState,
          [style.product_id]: res
        }));
      });
    });
  }, [stylesToCompare]);

  useEffect(() => {
    CustomInk.Metrics.pageViewTag('/catalog/compare/products', {
      attrs: {productCompare: stylesToCompare?.length}
    });
  }, []);

  return (
    <Box component="table" sx={{borderSpacing: 0, overflowX: 'auto'}}>
      <tbody>
        <tr>
          <ComparisonColumn rightBorder></ComparisonColumn>
          {stylesToCompare.map((style, index) => (
            <ProductImageAndTitleColumn
              key={`product-image-${style.product_id}`}
              style={style}
              rightBorder={index + 1 !== stylesToCompare.length}
            />
          ))}
        </tr>
        <tr>
          <ComparisonColumn rightBorder></ComparisonColumn>
          {stylesToCompare.map((style, index) => (
            <ComparisonProductActionsColumn
              key={`product-actions-${style.product_id}`}
              style={style}
              removeStyleToCompare={removeStyleToCompare}
              rightBorder={index + 1 !== stylesToCompare.length}
            />
          ))}
        </tr>
        {transformStylesForComparison(stylesToCompare, styleQuotes).map(
          (values) => (
            <tr>
              {values.map((value, index) => (
                <ComparisonColumn
                  key={`compare-row-${values[0]}-${index}`}
                  rightBorder={index + 1 !== values.length}
                >
                  {Array.isArray(value) ? (
                    <Stack>
                      {value.map((txt) => (
                        <Box>{txt}</Box>
                      ))}
                    </Stack>
                  ) : (
                    value
                  )}
                </ComparisonColumn>
              ))}
            </tr>
          )
        )}
        <tr>
          <ComparisonColumn rightBorder>Rating</ComparisonColumn>
          {stylesToCompare.map((style, index) => (
            <ComparisonColumn
              key={`product-rating-${style.product_id}`}
              rightBorder={index + 1 !== stylesToCompare.length}
            >
              <Rating
                ratingCount={style.rating_count}
                ratingScore={style.rating_score}
                name={style.name}
                generalComments={style.general_comments}
              />
            </ComparisonColumn>
          ))}
        </tr>
      </tbody>
    </Box>
  );
};

export default ProductComparison;
