import React from 'react';

function NoHits(props) {
  const {searchQuery} = props;

  return (
    <div className="pc-NoResults sb-Wrapper">
      <div className="pc-NoResults-content">
        <h3 className="pc-NoResults-content-header">{`We're sorry! Your search ${searchQuery} did not match any products.`}</h3>
        <div className="pc-NoResults-content-searchTips">
          <span className="label">Search Tips:</span>
          <ul>
            <li>Check your spelling</li>
            <li>Use fewer words</li>
            <li>Use different words</li>
          </ul>
        </div>
        <div className="pc-NoResults-content-ttarp">
          If you&apos;re still not finding what you want, browse our products
          below or please give us a call and we can help you.
          <a href="tel:800-293-4232">800-293-4232</a>
        </div>
      </div>
    </div>
  );
}

export default NoHits;
