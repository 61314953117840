import React from 'react';
import {
  LabIconsUploadIcon,
  RefreshIcon,
  Typography
} from '@customink/pigment-react';
import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';

const defaultInputAcceptAttr = 'image/*';

const DropZone = ({
  accept = defaultInputAcceptAttr,
  supportedFilesDescription,
  onUpload,
  isUploading
}) => {
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const acceptedMimeTypes = accept.split(',');
    const supportedFile = acceptedMimeTypes.some((mime) =>
      file.type.match(`^${mime}`)
    );
    if (supportedFile) onUpload(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const input =
      event.currentTarget.parentNode.querySelector('#pc-dropzone-input');
    input.click();
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    onUpload(event.target.files[0]);
  };

  return (
    <>
      <input
        id="pc-dropzone-input"
        className="pc-DropZone-input"
        type="file"
        accept={accept}
        autoComplete="off"
        tabIndex="-1"
        onChange={handleInputChange}
      />
      <button
        className="pc-DropZone"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={handleClick}
        disabled={isUploading}>
        {isUploading ? (
          <RefreshIcon className="pc-DropZone-icon spin" fontSize="large" />
        ) : (
          <LabIconsUploadIcon className="pc-DropZone-icon" fontSize="large" />
        )}
        <p className="pc-DropZone-text">
          Drag & Drop or
          <br />
          <Typography
            sx={{
              ...(!isUploading && {
                color: designTokens.color.blue.default.value
              })
            }}>
            Browse&nbsp;
          </Typography>{' '}
          Your Computer
        </p>
        <span className="pc-DropZone-supportedFilesDescription">
          {supportedFilesDescription}
        </span>
      </button>
    </>
  );
};

export default DropZone;
