import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';

const QuickQuote = ({
  handleQuantityChange,
  handleFrontColorChange,
  handleBackColorChange,
  quantity,
  frontColor,
  backColor
}) => {

  const [qqQuantity, setQqQuantity] = useState('');
  const [qqFrontColor, setQqFrontColor] = useState('');
  const [qqBackColor, setQqBackColor] = useState('');

  const applyQtyChange = (evt) => {
    const filteredValue = evt.target.value.replace(/\D/g, '').replace(/^0+(\d)/, '$1');
    setQqQuantity(filteredValue);
  }

  const applyFrontColorChange = (evt) => {
    const filteredValue = evt.target.value.replace(/\D/g, '').replace(/^(\d).*/, '$1');
    setQqFrontColor(filteredValue);
  }

  const applyBackColorChange = (evt) => {
    const filteredValue = evt.target.value.replace(/\D/g, '').replace(/^(\d).*/, '$1');
    setQqBackColor(filteredValue);
  }

  const debouncedHandleQuantityChange = useCallback(
    debounce(350, () => {
      handleQuantityChange(parseInt(qqQuantity));
    }), [qqQuantity]
  );

  const debouncedHandleFrontColorChange = useCallback(
    debounce(350, () => {
      handleFrontColorChange(parseInt(qqFrontColor));
    }), [qqFrontColor]
  );

  const debouncedHandleBackColorChange = useCallback(
    debounce(350, () => {
      handleBackColorChange(parseInt(qqBackColor));
    }), [qqBackColor]
  );

  useEffect(() => {
    if (qqQuantity.length > 0 && parseInt(qqQuantity) > 0) {
      debouncedHandleQuantityChange();
      return () => debouncedHandleQuantityChange.cancel();
    }
  }, [debouncedHandleQuantityChange]);

  useEffect(() => {
    if (qqFrontColor.length > 0 && parseInt(qqFrontColor) > 0) {
      debouncedHandleFrontColorChange();
      return () => debouncedHandleFrontColorChange.cancel();
    }
  }, [debouncedHandleFrontColorChange]);

  useEffect(() => {
    if (qqBackColor.length > 0 && parseInt(qqBackColor) >= 0) {
      debouncedHandleBackColorChange();
      return () => debouncedHandleBackColorChange.cancel();
    }
  }, [debouncedHandleBackColorChange]);

  useEffect(() => {
    setQqQuantity(quantity.toString());
  }, [quantity]);

  useEffect(() => {
    setQqFrontColor(frontColor.toString());
  }, [frontColor]);

  useEffect(() => {
    setQqBackColor(backColor.toString());
  }, [backColor]);

  return (
    <div className="pc-QuoteControl">
      <form className="pc-QuoteControl-form sb-Form sb-Form--inline">
         Pricing and availability for
        <div className="sb-Form-group sb-Form-group--mr">
          <input
            type="number"
            name="ord_qty"
            value={qqQuantity}
            id="ord_qty"
            min="1"
            className="pc-QuoteControl-form-input-algolia"
            onChange={(evt) => {
              applyQtyChange(evt);
            }}
          />
           items
        </div>
        <div className="sb-Util-hidden--sm">
           with a
          <div className="sb-Form-group sb-Form-group--mr">
            <input
              type="number"
              name="quote_fc"
              id="quote_fc"
              value={qqFrontColor}
              min="0"
              max="9"
              className="pc-QuoteControl-form-input-algolia"
              onChange={(evt) => {
                applyFrontColorChange(evt);
              }}
            />
          </div>
           color front and a
          <div className="sb-Form-group sb-Form-group--mr">
            <input
              type="number"
              name="quote_bc"
              id="quote_bc"
              value={qqBackColor}
              min="0"
              max="9"
              className="pc-QuoteControl-form-input-algolia"
              onChange={(evt) => {
                applyBackColorChange(evt);
              }}
            />
          </div>
           color back
        </div>
      </form>
    </div>
  );
};

export default QuickQuote;
