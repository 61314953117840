import {
  Alert,
  AlertTitle,
  Button,
  CardMedia,
  DialogLegacy,
  DialogLegacyContent,
  Grid,
  IconButton,
  LabIconsUploadIcon,
  RefreshIcon,
  Typography,
  XCloseIcon
} from '@customink/pigment-react';
import {Fade, Slide} from '@mui/material';
import Box from '@mui/material/Box';
import {useRollbar} from '@rollbar/react';
import React, {useState} from 'react';
import DropZone from '../../components/DropZone';
import useBreakpoint from '../../hooks/useBreakpoint';
import {uploadFile} from '../../src/apis/upload_service_client';
import {
  createCustomDesignPreviewUploadUrl,
  mmsImageBase
} from '../../utils/images';
import {getBaseLabUrlObject} from '../../utils/labLink';

const slideTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const fadeTransition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

function UploadModal({
  displayModal,
  fireMetricsEvent,
  recentUploads,
  setCustomDesignPreview,
  setDisplayModal,
  setRecentUploads
}) {
  const dialogTitle = 'Preview a Design';
  const dropZoneFileDesc = 'JPG, PNG, EPS, AI and PDF (Max 5 MB)';
  const isMobile = useBreakpoint() === 'sm';
  const rollbar = useRollbar();
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleModalClose = () => {
    setDisplayModal(false);
    setIsUploading(false);
    setErrorMessage(null);
  };
  const checkMmsImage = async (designImageUrl) => {
    // Used to determine whether the user upload will be properly handled by MMS Images (won't return 500). Static image
    // ref to prevent us from having to list through Algolia hits. This style is present on both prod and staging.
    const mmsImagesQuery = '/colors/176100/views/alt/front_medium.png';
    const img = new Image();
    const mmsImageUrl = new URL(`${mmsImageBase()}${mmsImagesQuery}`);

    mmsImageUrl.search = new URLSearchParams({
      design: designImageUrl,
      placeMax: 1,
      placeMaxPct: 0.8,
      placeUseProduct: 1,
      placeUseView: 'front'
    }).toString();
    img.src = mmsImageUrl.toString();

    return img.decode();
  };

  const handleImageUpload = (file) => {
    // File size limit is 5MB
    if (file.size > 5 * 1024 * 1024) {
      fireMetricsEvent('catalog/upload/file-too-large');
      setErrorMessage({
        description: 'Please resize your file to below 5MB.',
        title: 'File size too large'
      });
      return;
    }
    setIsUploading(true);
    uploadFile(file)
      .then(async (data) => {
        const designImageUrl = createCustomDesignPreviewUploadUrl(data);
        const uploadedAt = Date.now();

        // Check if MMS Images can handle the design. If not, raise an error (currently there's an issue with MMS Images
        // processing some byte sequences in the design files, which causes a 500 error).
        await checkMmsImage(designImageUrl)
          .then(() => {
            const designObject = {
              data,
              uploadedAt,
              url: designImageUrl
            };
            setCustomDesignPreview(designObject);
            setRecentUploads((currentRecentUploads) => [
              designObject,
              ...currentRecentUploads
            ]);
            fireMetricsEvent('catalog/upload/success');
            handleModalClose();
          })
          .catch((_error) => {
            fireMetricsEvent('catalog/upload/mms-images-failure');
            setErrorMessage({
              description: `Please try another file, or try uploading in the <a href=${getBaseLabUrlObject()}>Design Lab</a>.`,
              title: "Couldn't preview this upload"
            });
            setIsUploading(false);
          });
      })
      .catch((error) => {
        setIsUploading(false);
        setErrorMessage({
          description:
            'There was an error uploading your file. Please try again.',
          title: 'File upload failed'
        });
        fireMetricsEvent('catalog/upload/failure');
        rollbar.warning(
          'Error uploading preview upload to UploadService',
          error
        );
      });
  };
  const handleDropZoneClick = (file) => {
    setErrorMessage(null);
    fireMetricsEvent('catalog/upload/browse/dropzone');
    handleImageUpload(file);
  };
  const handleButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    fireMetricsEvent('catalog/upload/browse/button');
    const input = event.currentTarget.parentNode.querySelector(
      '#pc-styles-uploadcard-modal-input'
    );
    input.click();
  };
  const handleInputChange = (event) => {
    event.preventDefault();

    setErrorMessage(null);
    handleImageUpload(event.target.files[0]);
  };
  const selectRecentUpload = (index = 0) => {
    fireMetricsEvent('catalog/upload/add-recent');
    setCustomDesignPreview(recentUploads[index]);
    handleModalClose();
  };
  const removeRecentUpload = (index = 0) => {
    fireMetricsEvent('catalog/upload/remove-recent');
    setRecentUploads((currentRecentUploads) =>
      currentRecentUploads.filter((_, i) => i !== index)
    );
  };

  const dialogContent = (
    <DialogLegacyContent>
      <div className="pc-Modal pc-Styles-uploadCard-modal">
        <div className="pc-Modal pc-Styles-uploadCard-modal-uploadSection">
          <span>High resolution artwork will look best.</span>
          {errorMessage !== null && (
            <Alert severity="error">
              <AlertTitle>{errorMessage.title}</AlertTitle>
              <span
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{__html: errorMessage.description}}
              />
            </Alert>
          )}
          <input
            id="pc-styles-uploadcard-modal-input"
            className="pc-DropZone-input"
            type="file"
            accept="image/jpeg,image/png,.eps,.pdf,.ai"
            autoComplete="off"
            tabIndex="-1"
            onChange={handleInputChange}
          />
          <div className="pc-Styles-uploadCard-modal-uploadSection-dropzone">
            <DropZone
              className="pc-Styles-uploadCard-modal-uploadSection-dropzone"
              accept="image/jpeg,image/png,.eps,.pdf,.ai"
              supportedFilesDescription={dropZoneFileDesc}
              onUpload={handleDropZoneClick}
              isUploading={isUploading}
            />
          </div>
          <Button
            className="pc-Styles-uploadCard-modal-uploadSection-button"
            variant="primary"
            startIcon={
              isUploading ? (
                <RefreshIcon className="spin" />
              ) : (
                <LabIconsUploadIcon />
              )
            }
            size="large"
            onClick={handleButtonClick}
            disabled={isUploading}>
            Upload From Your Device
          </Button>
        </div>
        {recentUploads.length > 0 && (
          <div className="pc-Modal pc-Styles-uploadCard-modal-recentUploadsSection">
            <Typography variant="h2">Recent Uploads</Typography>
            <Grid
              container
              spacing={1}
              columns={{xs: 4}}
              justifyContent="flex-start"
              alignItems="normal"
              className="pc-Styles-uploadCard-modal-grid">
              {recentUploads.map((design, index) => {
                return (
                  <Grid
                    key={`recently-uploaded-image-${design?.data?.url}`}
                    item
                    xs={3}
                    className="pc-Styles-uploadCard-modal-grid-item">
                    <IconButton
                      fill
                      aria-label="anchorSmall"
                      className="pc-Styles-uploadCard-modal-grid-item-close"
                      onClick={() => removeRecentUpload(index)}>
                      <XCloseIcon fontSize="inherit" />
                    </IconButton>
                    <Box
                      className="pc-Styles-uploadCard-modal-grid-item-image"
                      onClick={(_event) => selectRecentUpload(index)}>
                      <CardMedia component="img" image={design.url} />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        )}
      </div>
    </DialogLegacyContent>
  );

  return (
    <DialogLegacy
      className={`pc-Styles-uploadCard-modal-dialog ${isMobile ? 'mobile' : ''}`}
      onClose={handleModalClose}
      title={dialogTitle}
      open={displayModal}
      TransitionComponent={isMobile ? slideTransition : fadeTransition}>
      {dialogContent}
    </DialogLegacy>
  );
}

export default UploadModal;
