import React from 'react';
import {DialogLegacy, DialogLegacyContent} from '@customink/pigment-react';

const ProductComparisonDialog = ({children, isOpen, handleClose}) => {
  return (
    <DialogLegacy
      title="Compare products"
      open={isOpen}
      onClose={handleClose}
      maxWidth={false}
    >
      <DialogLegacyContent
        dividers={false}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          overflowX: 'auto',
          pb: '1rem'
      }}>
        {children}
      </DialogLegacyContent>
    </DialogLegacy>
  );
};

export default ProductComparisonDialog;
