export default function priceGridQuantities(
  bulkMinimumQty,
  isSinglesEnabled = false,
  isSpecialty = false
) {
  const SINGLES_ENABLED_APPAREL = [1, 10, 25, 50, 100, 500];
  const SPECIALTY_TINY_MINIMUMS = [2, 4, 6, 8, 10];
  const DEFAULT_LESSER_MINIMUMS = [1, 2, 4, 8, 17, 83];
  const DEFAULT_HIGHER_MINIMUMS = [1, 2, 4, 10, 20, 50];

  if (isSinglesEnabled) {
    return SINGLES_ENABLED_APPAREL;
  }
  if (parseInt(bulkMinimumQty, 10) > 0) {
    if (isSpecialty && bulkMinimumQty < 6) {
      return [
        bulkMinimumQty,
        ...SPECIALTY_TINY_MINIMUMS.map(
          (mult) => Math.ceil((mult * bulkMinimumQty) / 10) * 10
        )
      ];
    }

    if (bulkMinimumQty < 25) {
      return DEFAULT_LESSER_MINIMUMS.map((mult) => mult * bulkMinimumQty);
    }
    return DEFAULT_HIGHER_MINIMUMS.map((mult) => mult * bulkMinimumQty);
  }
  return [];
}
