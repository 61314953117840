import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useRefinementList } from 'react-instantsearch';
import conciseSize from '../../utils/conciseSize';
import { filterGroupHeader } from '../utils/algolia_filters_utils';

const FilterGroup = (props) => {
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [displayShowMore, setDisplayShowMore] = useState(false);
  const defaultFilterCountLimit = 7;
  const { attribute, displayLimt, showMoreLimit, filterChangeHandler, values, sizeOrder, filterGroupList } = props;
  const algoliaProps = { ...props };
  delete algoliaProps.filterChangeHandler;

  const transformItems = useCallback(
    (items) => {
     if(sizeOrder && props.attribute === 'sizes') { // transform size filters and the order we show them in
      const sortedSizes =  items.sort((a, b) => {
        const positionA = sizeOrder.indexOf(a.value)
        const positionB = sizeOrder.indexOf(b.value)
        const sortPositionA = positionA > -1 ? positionA : Number.POSITIVE_INFINITY
        const sortPositionB = positionB > -1 ? positionB : Number.POSITIVE_INFINITY
        const shiftA = a.value.startsWith('Y') ? sizeOrder.length : 0
        const shiftB = b.value.startsWith('Y') ? sizeOrder.length : 0
        return (sortPositionA + shiftA ) - (sortPositionB + shiftB)
      })
      return sortedSizes?.map(size => Object.assign(size, { highlighted: conciseSize(size.value)}))
     } else if (props.attribute === 'fg-Battery-Capacity') {
        const itemSplit = items.reduce((a, c) => {
          a[c.value.startsWith('Up to') ? 'nonNumericStart' : 'numericStart'].push(c);
          return a;
        }, { nonNumericStart: [], numericStart: [] });
        let { nonNumericStart, numericStart } = itemSplit;
        let sortedNumberRanges =
          numericStart.sort((a,b) => {
            return (
              parseInt(a.value.split(' ')[0].replace(/,/g,'')) -
              parseInt(b.value.split(' ')[0].replace(/,/g,''))
            )
          })
        const itemArray = [...nonNumericStart, ...sortedNumberRanges]
        return itemArray;
     } else if (props.filterValuesOrder && Object.keys(props.filterValuesOrder).includes(props.attribute)) {
       const desiredOrder = props.filterValuesOrder[props.attribute]
       return items.sort((a, b) => desiredOrder.indexOf(a.value) - desiredOrder.indexOf(b.value))
     } else {
        const filterItems = values ? items.filter((item) =>
          values.map(v => v.toUpperCase()).includes(item.value.toUpperCase())
        ) : items
        filterItems.map((item) => {
          if (item.highlighted === 'Bpa Free') {
            item.highlighted = 'BPA Free'
          }
        })
        return filterItems;
     }
    },
    [items],
  );

  const { items, refine } = useRefinementList({
    ...algoliaProps,
    ...{transformItems}
  });

  const filterGroupHeaderName = filterGroupHeader(attribute, filterGroupList);
  const labelForAttribute = `fs-${filterGroupHeaderName}-`;
  const dataFilterType = `fs-${filterGroupHeaderName}`;

  const applyFilter = (event, item) => {
    filterChangeHandler();
    const dataFilterAction = item.isRefined ? 'remove' : 'add'
    window.dataLayer.push({
      event: 'catalog_filter',
      filter_type: dataFilterType,
      filter_value: item.value,
      filter_action: dataFilterAction
    })
    refine(item.value);
  }

  const displayShowMoreElement = () => {
    setDisplayShowMore((prevState) => !prevState);
  }

  const itemsToDisplay = () => {
    return items.slice(
      0, isShowingMore ? showMoreLimit : defaultFilterCountLimit
    )
  }

  const displayMore = () => {
    return itemsToDisplay().length < items.length;
  }

  const toggleShowMore = () => {
    setIsShowingMore((prevState) => !prevState);
  }

  const filterValuesOverLimit = () => {
    return items.length > defaultFilterCountLimit;
  }

  return (
    items.length > 0 &&
    <nav className='pc-FiltersGroup'>
        <header className={`pc-FiltersGroup-header ${!props.isCollapsedFilter && 'expanded'}`} onClick={() => displayShowMoreElement()}>{filterGroupHeaderName}</header>
        <div className='pc-FiltersGroup-values'>
          {itemsToDisplay()?.map((item) => {
            return(
              <label
                htmlFor={labelForAttribute + item.highlighted}
                key={item.highlighted}
                className='sb-Form-checkbox pc-FiltersGroup-value'
                data-insights-filter={`${labelForAttribute}:${item.highlighted}`}
              >
                <input
                  type="checkbox"
                  id={labelForAttribute + item.highlighted}
                  value={item.highlighted}
                  checked={item.isRefined} // when checked -> true (filter is applied)
                  onChange={() => {}}
                ></input>
                <a className='pc-FiltersGroup-link'
                  onClick={(event) => applyFilter(event, item)}
                >
                  <span className="sb-Form-indicator"></span>
                  {`${item.highlighted} (${item.count})`}
                </a>
              </label>
            )
          })}
          {filterValuesOverLimit() &&
          <a
            className='pc-FiltersGroup-toggle'
            onClick={toggleShowMore}
          >
          Show {displayMore ? 'more' : 'fewer'}
          </a>
          }
        </div>
    </nav>
  )
}

FilterGroup.propTypes = {
  attribute: PropTypes.string,
  limit: PropTypes.number,
  sortBy: PropTypes.array
}

export default FilterGroup;
