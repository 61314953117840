import React from 'react';

export default function MadeInUSABadge() {
  return (
    <div
      className="product-badge product-badge--usa"
      title="This is a made in USA product!"
    />
  );
}
