export const getSortOptions = (
  indexName,
  defaultQuoteQty,
  algorithm,
  prefix
) => {
  return [
    {label: 'Recommended', value: indexName},
    {label: 'Highest Rated', value: `${indexName}_rating_desc`},
    {label: 'Newest', value: `${indexName}_newest`},
    {
      label: 'Price: Low to High',
      value: priceIndexName(defaultQuoteQty, algorithm, prefix, indexName)
        .priceLowToHighIndex
    },
    {
      label: 'Price: High to Low',
      value: priceIndexName(defaultQuoteQty, algorithm, prefix, indexName)
        .priceHighToLowIndex
    }
  ];
};

export const getSimplifiedSortOptions = (
  indexName,
  defaultQuoteQty,
  algorithm
) => {
  return [
    {label: 'Recommended', value: 'none', analyticsTag: 'recommended'},
    {label: 'Highest Rated', value: 'rating', analyticsTag: 'rating desc'},
    {label: 'Newest', value: 'newest', analyticsTag: 'newest'},
    {
      label: 'Price: Low to High',
      value: simplifiedPriceIndexName(defaultQuoteQty, algorithm, indexName)
        .priceLowToHighIndex,
      analyticsTag: 'price asc'
    },
    {
      label: 'Price: High to Low',
      value: simplifiedPriceIndexName(defaultQuoteQty, algorithm, indexName)
        .priceHighToLowIndex,
      analyticsTag: 'price desc'
    }
  ];
};

export const getSortLabelFromValueMap = (
  indexName,
  defaultQuoteQty,
  algorithm,
  prefix
) => {
  let valueToLabelMap = {};
  const sortOptions = getSortOptions(
    indexName,
    defaultQuoteQty,
    algorithm,
    prefix
  );
  sortOptions.forEach(
    (option) => (valueToLabelMap[option.value] = option.label)
  );
  return valueToLabelMap;
};

export const getCatalogToAlgoliaSortMap = (
  indexName,
  defaultQuoteQty,
  algorithm,
  prefix
) => {
  return {
    newest_desc: `${indexName}_newest`,
    rating_desc: `${indexName}_rating_desc`,
    price_asc: priceIndexName(defaultQuoteQty, algorithm, prefix, indexName)
      .priceLowToHighIndex,
    price_desc: priceIndexName(defaultQuoteQty, algorithm, prefix, indexName)
      .priceHighToLowIndex
  };
};

export const getAlgoliaToCatalogSortMap = (
  indexName,
  defaultQuoteQty,
  algorithm,
  prefix
) => {
  let map = {};
  map[`${indexName}_newest`] = 'newest_desc';
  map[`${indexName}_rating_desc`] = 'rating_desc';
  map[
    priceIndexName(
      defaultQuoteQty,
      algorithm,
      prefix,
      indexName
    ).priceLowToHighIndex
  ] = 'price_asc';
  map[
    priceIndexName(
      defaultQuoteQty,
      algorithm,
      prefix,
      indexName
    ).priceHighToLowIndex
  ] = 'price_desc';
  return map;
};

export const getCatalogToAlgoliaSortMapSimplified = (
  defaultQuoteQty,
  algorithm,
  prefix
) => {
  return {
    newest_desc: 'newest',
    rating_desc: 'rating',
    price_asc: simplifiedPriceIndexName(defaultQuoteQty, algorithm, prefix)
      .priceLowToHighIndex,
    price_desc: simplifiedPriceIndexName(defaultQuoteQty, algorithm, prefix)
      .priceHighToLowIndex
  };
};

export const getAlgoliaToCatalogSortMapSimplified = (
  defaultQuoteQty,
  algorithm,
  prefix
) => {
  let map = {};
  map['newest'] = 'newest_desc';
  map['rating'] = 'rating_desc';
  map[
    simplifiedPriceIndexName(
      defaultQuoteQty,
      algorithm,
      prefix
    ).priceLowToHighIndex
  ] = 'price_asc';
  map[
    simplifiedPriceIndexName(
      defaultQuoteQty,
      algorithm,
      prefix
    ).priceHighToLowIndex
  ] = 'price_desc';
  return map;
};

const simplifiedPriceIndexName = (defaultQuoteQty, algorithm, indexName) => {
  const priceLowToHighIndex = defaultQuoteQty
    ? `unit-price-qty-${defaultQuoteQty}-${algorithm}_asc`
    : 'price_asc'; // what to do if no default quote quantity?
  const priceHighToLowIndex = defaultQuoteQty
    ? `unit-price-qty-${defaultQuoteQty}-${algorithm}_desc`
    : 'price_desc'; // what to do if no default quote quantity?
  return {
    priceLowToHighIndex: priceLowToHighIndex,
    priceHighToLowIndex: priceHighToLowIndex
  };
};

const priceIndexName = (defaultQuoteQty, algorithm, prefix, indexName) => {
  const priceLowToHighIndex = defaultQuoteQty
    ? `${prefix}-unit-price-qty-${defaultQuoteQty}-${algorithm}-asc`
    : `${indexName}_price_asc`;
  const priceHighToLowIndex = defaultQuoteQty
    ? `${prefix}-unit-price-qty-${defaultQuoteQty}-${algorithm}-desc`
    : `${indexName}_price_desc`;
  return {
    priceLowToHighIndex: priceLowToHighIndex,
    priceHighToLowIndex: priceHighToLowIndex
  };
};
