import React from 'react';
import { useEffect, useRef, useMemo } from 'react';
import { useCurrentRefinements } from 'react-instantsearch';
import { refinementsEqual } from '../utils/refinement_utils';

const CurrentFilters = ({setRefinementItems}) => {
  const { items } = useCurrentRefinements({});

  const prevRefinementsRef = useRef();

  const currentRefinements = useMemo(() => {
    if (refinementsEqual(items, prevRefinementsRef.current)) {
      return prevRefinementsRef.current;
    } else {
      prevRefinementsRef.current = items;
      return items;
    }
  }, [items]);

  useEffect(() => {
    setRefinementItems(currentRefinements);
  }, [currentRefinements, setRefinementItems]);
}

export default CurrentFilters;
