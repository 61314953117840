import React from 'react';
import { useMenu } from 'react-instantsearch';

const VirtualMenu = (props) => {
  useMenu(props);

  return null;
}

export default VirtualMenu;
