import React, {useRef} from 'react';
import {trackEcoFriendlyHoverEvent} from '../../utils/metrics/listingsMetrics';

export default function EcoFriendlyBadge({productName}) {
  const timerRef = useRef();

  const handleMouseEnter = () => {
    timerRef.current = setTimeout(() => {
      trackEcoFriendlyHoverEvent(productName);
    }, 300);
  };

  const handleMouseLeave = () => {
    clearTimeout(timerRef.current);
  };

  return (
    <div
      className="product-badge product-badge--eco"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      title="This is an eco-friendly product!"
    />
  );
}
