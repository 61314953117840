import {Alert, Link} from '@customink/pigment-react';
import React from 'react';

const ColorLimitAlert = () => {
  const getPathWithoutColorLimit = () => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete('color_limit');

    return currentUrl.toString();
  };

  return (
    <Alert severity="info" data-testid="colorLimitAlert">
      These products are compatible with the amount of colors in your design. To
      see the full category, you can{' '}
      <Link data-testid="viewAllProductsLink" href={getPathWithoutColorLimit()}>
        view all products
      </Link>{' '}
      instead.
    </Alert>
  );
};

export default ColorLimitAlert;
