export const handleQuickQuoteVisibility = (isVisible) => {
  const quickQuoteElement = document.querySelector('.pc-QuoteControl');
  if (quickQuoteElement) {
    if (isVisible && quickQuoteElement?.classList && !quickQuoteElement.classList.contains('is-visible')) {
      quickQuoteElement.classList.add('is-visible');
    } else if (!isVisible && quickQuoteElement?.classList?.contains('is-visible')) {
      quickQuoteElement.classList.remove('is-visible');
    }
  }
}
