import React from 'react';
import {Box, Button, Divider} from '@customink/pigment-react';
import ComparisonColumn from './ComparisonColumn';

const ComparisonProductActionsColumn = ({
  style,
  removeStyleToCompare,
  rightBorder
}) => {
  return (
    <ComparisonColumn rightBorder={rightBorder}>
      <Box sx={{display: 'flex', flexDirection: 'row', gap: '0.5rem'}}>
        <Button href={style.productLink} variant="link">
          View Details
        </Button>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Button onClick={() => removeStyleToCompare(style)} variant="link">
          Remove
        </Button>
      </Box>
    </ComparisonColumn>
  );
};

export default ComparisonProductActionsColumn;
