import React from 'react';
import { usePagination } from 'react-instantsearch';

const VirtualPagination = (props) => {
  usePagination(props);

  return null;
}

export default VirtualPagination;
