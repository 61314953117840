import './index.scss';
import React from 'react';
import {Box, Typography} from '@customink/pigment-react';

const PriceDetailsContent = ({backColor, frontColor, priceDetailQuoteData}) => {
  return (
    <Box className="pc-Price-Details-Content">
      <Box alignSelf="stretch">
        <Typography className="pc-Price-Details-Content-header-text">
          {`For a ${Math.max(frontColor, backColor)}-color, ${backColor > 0 ? 2 : 1}-sided design:`}
        </Typography>
        <Box className="pc-Price-Details-Content-grid-header-row">
          <Typography>Quantity</Typography>
          <Typography>Price Per Item</Typography>
        </Box>
        {priceDetailQuoteData &&
          Object.entries(priceDetailQuoteData).sort(
            (a, b) => parseInt(a[0], 10) - parseInt(b[0], 10)
          ).map(
            ([quantity, pricePerItem], index) => (
              <Box
                key={`pc-Price-Details-Content-grid-row-${quantity}`}
                backgroundColor={index % 2 === 0 && '#F4F4F4'}
                className="pc-Price-Details-Content-grid-row">
                {' '}
                <Typography>
                  {`${quantity}`}{index === 0 && ' (minimum)'}
                </Typography>
                <Typography>${pricePerItem}</Typography>
              </Box>
            )
          )}
      </Box>
      <Box className="pc-Price-Details-Content-footer">
        <Typography>All-inclusive pricing includes</Typography>
        <ul>
          <li>
            <Typography>
              <strong>FREE</strong> delivery
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>FREE</strong> artwork review
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>FREE</strong> artwork setup
            </Typography>
          </li>
        </ul>
      </Box>
    </Box>
  );
};

PriceDetailsContent.defaultProps = {
  backColor: 0,
  frontColor: 1
};

export default PriceDetailsContent;
