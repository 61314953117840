import React from 'react';
import { useClearRefinements } from 'react-instantsearch';

const CustomClearRefinements = (props) => {

  const { canRefine, refine } = useClearRefinements({
    ...props,
    excludedAttributes: ['compatible_type', 'query', 'sort_by']
  });

  const removeFilters = (event) => {
    event.preventDefault();
    ProductCatalog.GtmListing.fireImpression();
    window.dataLayer.push({
      event: "catalog_filter_remove",
      filter_remove_type: "all",
      filter_remove_value: "Remove Filters"
    });
    if(props.quoteQuantityDirty) {
      props.onRemoveOrderSize();
      refine();
    } else {
      refine();
    }
  }

  return (
    <div className="pc-selectedFilters-removeAll">
      <a onClick={(event) => removeFilters(event)}>Remove Filters</a>
    </div>
  )
}

export default CustomClearRefinements;
