import React, { useState, useEffect } from 'react';
import { useConnector } from 'react-instantsearch';
import connectStats from 'instantsearch.js/es/connectors/stats/connectStats';

const Stats = ({
  applyUserQuery,
  applySearchQuery,
  applyStatData,
  siteWideSearch
}) => {

  const useStats = () => {
    return useConnector(connectStats, {});
  }

  const { nbHits, query } = useStats();

  useEffect(() => {
    const displayProductResults = siteWideSearch ? `${nbHits} Results for ${query}` : `${nbHits} Products`;

    applyUserQuery(query);
    applySearchQuery(query);
    applyStatData(displayProductResults);
  }, [siteWideSearch, nbHits, query]);

}

export default Stats;
