export const calculateQuote = (productId, quantity, fc, bc) => {
  const quoteParams = encodeURI(`quote[items[][quantity[0]]]=${quantity}&quote[items[][product_id]]=${productId}&quote[color_counts[front]]=${fc}&quote[color_counts[back]]=${bc}&quote[postal_code]=22031`);
  const path = "/products/quotes/new";

  if (parseInt(quantity) > 0) {
    return new Promise((resolve, reject) => {
      $.ajax(path, {
        type: 'GET',
        dataType: 'json',
        data: quoteParams,
        error: (jqXHR, textStatus, errorThrown) => {
          return resolve('error calculating price');
        },
        success: (res) => resolve(res.original.each)
      });
    });
  } else {
    return new Promise((resolve, reject) => resolve(""));
  }
}
