import React from 'react';
import { isCatalogPath, isIframer } from 'src/utils/url';

const CrossSubcategoryNavigation = ({ subcategoryNavigation }) => {
  // parentCategory is the umbrella category that the subcategories belong to with the following unified
  // category structure:
  //    type Category = { name: :string, path: :string[] };
  const parentCategory = subcategoryNavigation?.parent_category;
  // A list of category objects with identical structure described above
  const categories = subcategoryNavigation?.sibling_categories;
  const currentCategory = categories?.find((category) => category.path === window.location.pathname)

  const fireMetricsEvent = (category, action) => {
    CustomInk.Metrics.fireEvent('plp subcat nav', category.toLowerCase(), action.toLowerCase());
    window.dataLayer.push({
      event: 'catalog_filter',
      filter_type: 'cross-subcategory-navigation',
      filter_value: category.toLowerCase(),
      filter_action: action.toLowerCase()
    });
  }

  const isSelected = (category) => category?.path === currentCategory?.path;

  if (!parentCategory || !categories) return null;

  return (
    <nav className='pc-FiltersGroup'>
      <header className='pc-FiltersGroup-header expanded'
              onClick={event => fireMetricsEvent(parentCategory.name, event.target.classList.contains('expanded') ? 'collapse' : 'expand')}>
        {parentCategory.name}
      </header>
      <div className='pc-FiltersGroup-values-catSubLinks'>
        {categories.map((category) => {
          return (
            <a key={category.path}
               className={`pc-FiltersGroup-values-catSubLink ${isSelected(category) ? "selected" : ""}`}
               href={category.path}
               onClick={() => fireMetricsEvent(parentCategory.name, category.name)}
               {...(isIframer() && !isCatalogPath(category.path) && { target: '_blank' })}
            >
              {category.name}
            </a>
          )
        })}
      </div>
    </nav>
  );
}

export default CrossSubcategoryNavigation;
