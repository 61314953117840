import conciseSize from '../../../../utils/conciseSize';

export const COMPARISON_ROW_TITLES = {
  totalColors: 'Total Colors',
  sizes: 'Sizes',
  turnTypes: 'Turn Times',
  individualShipEligible: 'Individual-ship Eligible',
  internationalShipEligible: 'International-ship Eligible',
  specialty: 'Specialty',
  inkLimit: 'Ink Limit',
  scaleFactor: 'Scale Factor',
  priceOneColorOnASide: 'Price (1 color on 1 side)',
  decorationMethod: 'Decoration Method',
  styleNumber: 'Style #',
  material: 'Material',
  features: 'Features',
  sizing: 'Sizing',
  additionalPrintAreas: 'Additional Print Areas',
  minimumQuantity: 'Minimum Quantity',
  coordinationStyles: 'Coordinating Styles'
};

const MASKS_SIZE_OVERRIDE_STYLES = [
  862700, 895900, 855000, 885900, 839400, 986900, 987000
];

const sizingSummary = (style) => {
  if (MASKS_SIZE_OVERRIDE_STYLES.includes(parseInt(style.product_id))) {
    return 'Youth & Adult';
  }

  const [first, ...rest] = style.sizes || [];
  const last = rest.pop();

  return last
    ? `${conciseSize(first)}-${conciseSize(last)}`
    : conciseSize(first);
};

const humanBoolean = (boolValue) => {
  return boolValue ? 'Yes' : 'No';
};

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const getDecorationMethod = (style) => {
  if (style.has_singles_enabled_colors) {
    return 'Screen printing or high-quality digital printing';
  }

  return capitalize(style?.decoration_method);
};

const styleMapper = (style, styleQuotes) => {
  return {
    totalColors: style.colors.length,
    sizes: sizingSummary(style),
    turnTypes: `Rush ${style.rush_turn_time} / Standard ${style.turn_time} Days`,
    individualShipEligible: humanBoolean(style.individual_ship_eligible),
    internationalShipEligible: humanBoolean(style.international_ship_eligible),
    specialty: humanBoolean(style.specialty),
    inkLimit: style.color_limit,
    scaleFactor: style.scale_factor,
    priceOneColorOnASide: styleQuotes?.[style.product_id]
      ? styleQuotes[style.product_id]
      : 'Loading...',
    decorationMethod: getDecorationMethod(style),
    styleNumber: style.mill_no,
    material: style.material?.replace('*', '').split(/\*/g),
    features: style.features?.replace('*', '').split(/\*/g),
    sizing: style.sizing,
    additionalPrintAreas: capitalize(style.additional_print_areas),
    minimumQuantity: style.minimum_quantity,
    coordinationStyles: style.complements_badge
  };
};

export const transformStylesForComparison = (styles, styleQuotes) => {
  const stylesMap = styles.map((s) => styleMapper(s, styleQuotes));
  return Object.entries(COMPARISON_ROW_TITLES).map(([key, title]) => {
    return [title, ...stylesMap.map((style) => style[key])];
  });
};
