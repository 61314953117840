import React from 'react';
import {
  Box,
  IconButton,
  Stack,
  Typography,
  XCloseIcon
} from '@customink/pigment-react';
import {styled} from '@customink/pigment-react/lib/themeCustomink';

const ComparisonItemDescription = styled(Typography)({
  color: 'white',
  fontSize: '0.8125rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: '1.25rem',
  maxHeight: '3.75rem',
  '-webkit-line-clamp': 3,
  '-webkit-box-orient': 'vertical',
});

const RemoveIconButton = styled(IconButton)({
  // override the default styles that are applied to IconButton
  '&:hover': {
    backgroundColor: '#fff'
  },
  border: '1px solid rgba(0, 0, 0, .85)',
  height: '1rem',
  marginBottom: '-0.8rem',
  marginRight: '-0.25rem',
  width: '1rem'
});

const ComparisonItem = ({compact, imageUrl, name, onRemove}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
        width: compact ? '8rem' : '12rem'
      }}
    >
      <Stack alignItems="flex-end" height="3.125rem" width="3.125rem">
        <RemoveIconButton
          fill
          onClick={onRemove}
          aria-label="anchorSmall"
          size="small"
        >
          <XCloseIcon fontSize="inherit" />
        </RemoveIconButton>
        <img
          style={{height: '3.125rem', objectFit: 'contain', width: '3.125rem'}}
          src={imageUrl}
          alt={name}
        />
      </Stack>
      <ComparisonItemDescription>{name}</ComparisonItemDescription>
    </Box>
  );
};

export default ComparisonItem;
