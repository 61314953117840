import {
  Box,
  Button,
  Stack,
  Typography,
  useBreakpoint
} from '@customink/pigment-react';
import React, {useState} from 'react';
import ComparisonItem from './ComparisonItem';
import {ProductComparison} from '../ProductComparison';
import ProductComparisonDialog from '../ProductComparisonDialog';

const ComparisonItemEmptySlot = ({compact}) => {
  return (
    <Box
      sx={{
        border: '1px solid rgba(255, 255, 255, .35)',
        borderRadius: '0.125rem',
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
        width: compact ? '8rem' : '12rem'
      }}
    />
  );
};

const ComparisonPanelContainer = ({children, isMd, isXl}) => {
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        borderRadius: '2px 2px 0 0',
        display: 'flex',
        flexDirection: 'row',
        gap: isMd ? '1rem' : '2rem',
        justifyContent: 'space-between',
        padding: '1rem 2rem',
        paddingBottom: '0.5rem',
        transition: 'bottom .35s ease, opacity .35s ease',
        width: isXl ? '77rem' : '60rem'
      }}
    >
      {children}
    </Box>
  );
};

const ComparisonForm = ({compareHandler, styles}) => {
  const [isProductComparisonOpen, setIsProductComparisonOpen] = useState(false);
  const isXl = useBreakpoint('xl');
  const isMd = useBreakpoint('md');

  const onCompareProducts = (e) => {
    CustomInk.Metrics.fireEvent('compare products', 'compare products', 'tray');
    setIsProductComparisonOpen(true);
  };

  const canCompare = styles && styles?.length > 1;

  const removeStyleToCompare = (style) => {
    compareHandler(
      styles?.filter((compareStyle) => style.name !== compareStyle.name),
      CustomInk.Metrics.fireEvent('compare products', 'deselect', 'tray')
    );
  };

  const removeAllStyleToCompare = () => {
    compareHandler(
      [],
      CustomInk.Metrics.fireEvent('compare products', 'clear all', 'tray')
    );
  };

  const emptySlotCount = 4 - styles.length;

  return (
    <Box
      sx={{
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        height: '6rem',
        justifyContent: 'center',
        position: 'fixed',
        width: '100%',
        zIndex: 10
      }}
    >
      <ProductComparisonDialog
        isOpen={isProductComparisonOpen}
        handleClose={() => setIsProductComparisonOpen(false)}
      >
        <ProductComparison
          stylesToCompare={styles}
          compareHandler={compareHandler}
          removeStyleToCompare={removeStyleToCompare}
        />
      </ProductComparisonDialog>
      <ComparisonPanelContainer isMd={isMd} isXl={isXl}>
        <Box sx={{display: 'flex', gap: '2rem', width: '80%'}}>
          {styles.slice(0, 4).map((style) => (
            <ComparisonItem
              key={`item-${style.product_id}`}
              compact={isMd}
              imageUrl={style.compareImageUrl}
              name={style.name}
              onRemove={() => removeStyleToCompare(style)}
            />
          ))}
          {emptySlotCount > 0 &&
            [...Array(emptySlotCount)].map((_, val) => (
              <ComparisonItemEmptySlot
                key={`empty-slot-${val}`}
                compact={isMd}
              />
            ))}
        </Box>
        <Box sx={{alignItems: 'center', display: 'flex', width: '20%'}}>
          {canCompare ? (
            <Stack gap="0.25rem" width="100%">
              <Button size="small" onClick={onCompareProducts}>
                Compare Products
              </Button>
              <Button
                size="small"
                variant="secondary"
                onClick={() => removeAllStyleToCompare()}
              >
                Clear All
              </Button>
            </Stack>
          ) : (
            <Stack
              gap={isMd ? '0.25rem' : '0.5rem'}
              alignItems="center"
              width="100%"
            >
              <Typography
                fontWeight="bold"
                color="white"
                fontSize="1rem"
                textAlign="center"
              >
                Add More to Compare
              </Typography>
              <Typography color="white" fontSize="0.825rem" textAlign="center">
                Maximum of 4 Products
              </Typography>
            </Stack>
          )}
        </Box>
      </ComparisonPanelContainer>
    </Box>
  );
};

export default ComparisonForm;
