import { event } from 'jquery';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useNumericMenu } from 'react-instantsearch';
import { numericMenuEqual } from '../../utils/refinement_utils';

const MinOrderQty = ({
  attribute,
  internalCatalog,
  quantity,
  quoteQuantityDirty,
  handleQuantityChange,
  onRemoveOrderSize,
  algoliaIndex
}) => {

  const [displayShowMore, setDisplayShowMore] = useState(true);
  const [numericMenuConfig, setNumericMenuConfig] = useState({
    attribute: attribute,
    items: [{ label: 'All' }]
  });

  const { items, refine } = useNumericMenu(numericMenuConfig);

  const prevItemsRef = useRef();

  const currentItems = useMemo(() => {
    if (numericMenuEqual(items, prevItemsRef.current)) {
      return prevItemsRef.current;
    } else {
      prevItemsRef.current = items;
      return items;
    }
  }, [items]);

  useEffect(() => {
    let isMounted = true;
    algoliaIndex
    .searchForFacetValues('min_order_qty', '')
    .then((res) => {
      if (isMounted) {
        const facetHits = res.facetHits;
        const minOrderQtyValues = facetHits.map(hit => parseInt(hit.value));
        const minOrderQty = parseInt(quantity);
        if (!minOrderQtyValues.includes(minOrderQty) && minOrderQty > 0 && quoteQuantityDirty) {
          const minOrderQtyRecord = {
            value: minOrderQty,
            highlighted: minOrderQty.toString()
          };
          facetHits.unshift(minOrderQtyRecord);
        }
        const qtyAsItem = numericMenuConfig.items.find(item => item?.end === minOrderQty);
        if (!qtyAsItem && facetHits.length > 0) {
          const numberItems = facetHits.map((facetValue) => {
            return {
              label: facetValue.value.toString(),
              end: parseInt(facetValue.value)
            }
          });
          const allMinOrderQtyRecord = { label: 'All' };
          numberItems.unshift(allMinOrderQtyRecord);
          setNumericMenuConfig({
            attribute: attribute,
            items: numberItems
          });
        }
      }
    })
    .catch((error) => {
      console.error('Facet search error', error);
    });
    return () => { isMounted = false; }; // cleanup function on unmount or before re-run
  }, [quantity]);

  const applyFilter = (event, item, allItem) => {
    event.preventDefault();
    if (item.isRefined) {
      refine(allItem.value);
      onRemoveOrderSize();
    } else {
      handleQuantityChange(parseInt(item.label));
    }
  }

  const handleShowMore = (event) => {
    event.preventDefault();
    setDisplayShowMore((prevValue) => !prevValue);
  }

  useEffect(() => {
    const itemToRefine = currentItems.find(item => item.label === quantity.toString());
    if (itemToRefine && !itemToRefine.isRefined && quoteQuantityDirty) {
      refine(itemToRefine.value);
    }
  }, [currentItems, quantity]);

  const notAll = (item) => item.label !== 'All';
  const showAllItem = items.find(item => item?.label === 'All');
  const itemsToDisplay = displayShowMore ? items.filter(notAll).slice(0,7) : items.filter(notAll);

  return (
    <nav className="pc-FiltersGroup is-Internal" style={{display: internalCatalog ? 'block': 'none'}}>
      <header className='pc-FiltersGroup-header expanded' onClick={(event) => handleShowMore(event)}>Order Size</header>
      <div className='pc-FiltersGroup-values'>
        {
          itemsToDisplay.map((item) => {
            return (
              <label
                htmlFor={`min-order-qty-${item.label}`}
                key={`min-order-qty-key-${item.label}`}
                className='sb-Form-checkbox pc-FiltersGroup-value'
              >
                <input
                  type="checkbox"
                  id={`min-order-qty-${item.label}`}
                  value={item.label}
                  checked={item.isRefined}
                  onChange={() => {}}
                />
                <a className='pc-FiltersGroup-link'
                  onClick={(event) => applyFilter(event, item, showAllItem)}
                >
                  <span className="sb-Form-indicator"></span>
                  {`${item.label}`}
                </a>
              </label>
            )
          })
        }
        <a
          className='pc-FiltersGroup-toggle-min-order-qty'
          onClick={(event) => handleShowMore(event)}
        >
        {displayShowMore ? 'Show more': 'Show fewer'}
        </a>
      </div>
    </nav>
  )
}

export default MinOrderQty
