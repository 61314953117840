import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { calculateQuote } from '../utils/quote';

const QuickView = (props) => {
  const [selectedColor, setSelectedColor] = useState(props.style.colors[0]);
  const [minQtyEnabled, setMinQtyEnabled] = useState(false);
  $(".mfp-bg").length < 1 && $('body').prepend($('<div class="mfp-bg pc-Modal pc-QuickViewModal mfp-ready"></div>'));
  const { style } = props;
  const [quotes, setQuotes] = useState([]);

  const onClose = () => {
    $('.mfp-bg').remove();
    props.onClose('');
  }

  const calculateAndSetQuote = (styleId, quantity) => {
    return calculateQuote(styleId, quantity, 1, 0).then(val => {
      calculateQuote(style.product_id, quantity, 1, 0).then((val) => {
        let priceNum = parseFloat(val);
        let priceUsd = isNaN(priceNum) ? '' : priceNum.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
       setQuotes(quotes => [...quotes, `${priceUsd} @ ${quantity} each`]);  
      }).catch(err => {
        console.log('err');
        setQuotes(quotes => [...quotes, ` @ ${quantity} each`]);     
      });
    });
  }

  useEffect(() => {
    const quantities = style.specialty ? [100, 250, 500, 1000] : [25, 50, 100, 250];
    Promise.resolve()
      .then(() => calculateAndSetQuote(style.product_id, quantities[0]))
      .then(() => calculateAndSetQuote(style.product_id, quantities[1]))
      .then(() => calculateAndSetQuote(style.product_id, quantities[2]))
      .then(() => calculateAndSetQuote(style.product_id, quantities[3]))
  }, []);

  const renderRow = (title, value, description) => {
    const titleDescription = `${title} ${description ? '</br>' : ''} ${description ? description : ''}`;
    return (
        <tr className="pc-QuickView-row">
            <td className="pc-QuickView-rowHeader"
                dangerouslySetInnerHTML={{__html: titleDescription}}
            >
            </td>
            <td className="pc-QuickView-cell" dangerouslySetInnerHTML={{__html: value}}></td>  
        </tr>
    );
  }

  const getSizingGuidePopup = (styleId) => {
    const popupOptions = "width=640,height=700,scrollbars=yes,tollbar=yes,menubar=no,location=yes,reziable=yes";
    const link = `${window.location.origin}/items/sizing/${styleId}_lineup/standard.htm`;
    return `<a 
        data-popup-name="sizinginfo"
        data-popup-options=${popupOptions}
        rel="popup"
        href=${link}
    >
        View Sizing Guide
    </a>`   
  }

  const getSizeAvailabilityPopup = (styleId) => {
    const popupOptions = "width=620,height=785,scrollbars=yes,tollbar=no,menubar=no,location=yes,reziable=yes";
    const link = `${window.location.origin}/styles/sizing/${styleId}`;
    return `<a 
        data-popup-name="availability" 
        data-popup-options=${popupOptions} 
        rel="popup" 
        href=${link}
    >
        Availability
    </a>`
  }

  const getQuickQuotePopup = (styleId) => {
    const popupOptions = "width=400,height=615,scrollbars=yes,tollbar=yes,menubar=no,location=yes,reziable=yes";
    const link = `${window.location.origin}/quotes?product_id=${styleId}`;
    return `<a 
        data-popup-name="quickquote" 
        data-popup-options=${popupOptions} 
        rel="popup" 
        href=${link}
    >
        Get a Quick Quote
    </a>`    
  }

  const getConstraintsPopup = (breadcrumbs, styleId) => {
    const popupOptions = "width=640,height=700,scrollbars=yes,tollbar=yes,menubar=no,location=yes,reziable=yes";
    const path = breadcrumbs[breadcrumbs.length - 1].path;
    const deconstructedPath = path.split('/');
    const link = `/products/styles/${deconstructedPath[deconstructedPath.length - 2]}/${styleId}/constraints`;
    return `<a 
        data-popup-name="sizinginfo"
        data-popup-options=${popupOptions}
        rel="popup"
        href=${link}
    >
        Constraints
    </a>`
  }

  const isSelectedColor = (color) => {
    return selectedColor.id === color.id;
  }

  const renderQuickviewColorSwatches = (colors) => {
    return (<div>
        <span className="pc-QuickView-bulkColorCount is-Visible">{colors.length}</span>
        <div className="pc-QuickView-colors">
            Selected Color: 
            <span className="pc-Swatch-noninteractive" 
                title={selectedColor?.name}
                style={{backgroundColor: selectedColor?.rbgs[0]}}
            >
            </span>
            <span>
              {selectedColor.name}
            </span> 
            <div className="pc-QuickView-ordQty">
                <label>Ordering fewer than 6</label>
                <input
                    className="pc-ToolBar-toggle pc-ToolBar-toggle-min-qty"
                    id="pc-ToolBar-toggle-2"
                    type="checkbox"
                    aria-labelledby="pc-ToolBar-toggle-2-label"
                    checked={minQtyEnabled}
                    onChange={() => {}}
                  />
                  <div
                    className="pc-ToolBar-toggle-button"
                    onClick={() => {
                      setMinQtyEnabled(!minQtyEnabled)
                    }}
                  />
            </div>
        </div>
        <div className="pc-SwatchSet">
            <div className="pc-SwatchSet-swatches pc-QuickView-swatches">
            {
                colors.map((color) => {
                  return(
                    <span className={isSelectedColor(color) ? 'pc-Swatch is-selected': 'pc-Swatch'}
                    onClick={() => setSelectedColor(color)}
                    style={{ backgroundColor: color?.rbgs[0] }}
                    title={color.name}
                    key={color.name}
                    >
                    </span>
                )})
            }
            </div>
        </div>
    </div>);
  }

  return (
    <div className={"mfp-wrap mfp-align-top mfp-auto-cursor pc-Modal pc-QuickViewModal mfp-ready"}
      data-before={style.name}
    >
        <div className="mfp-container mfp-ajax-holder mfp-s-ready">
            <div className="mfp-content">
                <div className="pc-QuickView">
                    <table className="pc-QuickView-table pc-QuickView-table-striped">
                        <tbody>
                            <tr className="pc-QuickView-row">
                                <td className="pc-QuickView-rowHeader">
                                    Total Colors
                                </td>
                                <td className="pc-QuickView-cell">
                                    {renderQuickviewColorSwatches(minQtyEnabled ? style.colors?.filter(b => b.min_qty >=6) : style.colors)}
                                </td>
                            </tr>
                            {renderRow('Sizes', style.sizes?.join(', '), getSizingGuidePopup(style.product_id))}
                            {renderRow('Turn Times', `Rush ${style.rush_turn_time} Days/ Standard ${style.turn_time} Days`)}
                            {renderRow('Individual-ship Eligible', `${style.individual_ship_eligible ? 'Yes' : 'No'}`)}
                            {renderRow('International-ship Eligible', `${style.international_ship_eligible ? 'Yes' : 'No'}`)}
                            {renderRow('Ink Limit', style.color_limit)}
                            {renderRow('Scale Factor', style.scale_factor)}
                            {renderRow('Price (1 color on 1 side)', `${quotes.join('<br/>')}`, getQuickQuotePopup(style.product_id))}
                            {renderRow('Decoration Type', style.decoration_method)}
                            {renderRow('Style #', style.mill_no)}
                            {renderRow('Manufacturer', style.manufacturer)}
                            {renderRow('Material', style.material?.replace('*', '').replace(/\*/g, '<br/>'))}  
                            {renderRow('Features', style.features?.replace('*', '').replace(/\*/g, '<br/>'))}
                            {renderRow('Sizing', style.sizing, getSizeAvailabilityPopup(style.product_id))}
                            {renderRow('Additional Print Areas', style.additional_print_areas, style.breadcrumbs && getConstraintsPopup(style.breadcrumbs, style.product_id))}
                            {renderRow('Decoration Days', style.standard_decoration_days)}
                            {renderRow('Specialty', style.specialty)}
                            {renderRow('Other Notes', style.supplier_notes)}
                        </tbody>
                    </table>
                </div>

            </div>

        </div>
        <span className="sb-Icon--close mfp-close" onClick={() => onClose()}/>
    </div>
  );
};

export default QuickView;
