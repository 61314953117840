import React from 'react';
import { useRefinementList } from 'react-instantsearch';

const Halftones = (props) => {

  const { internalCatalog, filterChangeHandler, ...algoliaProps } = props;
  const {items, refine} = useRefinementList(algoliaProps);

  const applyHalftonesOption = (item) => {
    const onOrOffStatus = !item.isRefined ? 'on' : 'off';
    CustomInk.Metrics.fireEvent(
      'event',
      'product halftones',
      onOrOffStatus
    );
    refine(item.value);
  }

  return items.length < 1 ? '' : (
    <nav className='pc-FiltersGroup pc-FiltersGroup-halftones is-Internal' style={{display: internalCatalog ? 'block': 'none'}}>
      <header className='pc-FiltersGroup-header expanded'>Halftones</header>
      <div className='pc-FiltersGroup-values'>
        {
          items.filter((i) => i.value === 'true').map((item) => {
            return (
              <div className='sb-Form-group' key={`halftones_${item.value}`}>
                <label className='sb-Form-checkbox'>
                <input
                  className='pc-ToolBar-toggle pc-ToolBar-toggle-halftones'
                  type='checkbox'
                  name='halftones[]'
                  id={`halftones_${item.value}`}
                  checked={item.isRefined}
                  onChange={() => applyHalftonesOption(item)}
                />
                <span className="sb-Form-indicator"></span>
                Accepts Halftones
                </label>
              </div>
            )
          })
        }
      </div>
    </nav>
  )
}

export default Halftones;
