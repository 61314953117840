import React from 'react';
import {useRefinementList, useInstantSearch} from 'react-instantsearch';
import {validMinQtyColorsAvailable} from '../utils/algolia_filters_utils';

const MinimumQuantity = (props) => {
  const {filterChangeHandler} = props;
  const algoliaProps = {...props};
  delete algoliaProps.filterChangeHandler;
  const {items, refine} = useRefinementList(algoliaProps);
  const {results} = useInstantSearch(props);

  const minimumQuantityToggleClicked = (event, item) => {
    const onOrOffStatus = !item.isRefined ? 'on' : 'off';
    CustomInk.Metrics.fireEvent(
      'product quantity',
      'order fewer than six',
      onOrOffStatus
    );
    filterChangeHandler();
    window.dataLayer.push({
      event: 'catalog_filter',
      filter_type: 'fs-order_qty',
      filter_value: item.value,
      filter_action: item.isRefined ? 'remove' : 'add'
    });
    refine(item.value);
  };

  const renderMinQtyToggleCrossSubcatNav = () => {
    return (
      <nav className="pc-FiltersGroup">
        <div className="pc-FiltersGroup-ordQty">
          <div className="pc-FiltersGroup-ordQty-values">
            <div className="pc-FiltersGroup-ordQty-values-label">
              Ordering fewer than&nbsp;6?
            </div>
            {items?.map((item) => {
              let hideItem = item.value === 'false';
              return (
                <React.Fragment key={`${item.label}} fragment`}>
                  <input
                    className="pc-ToolBar-toggle pc-ToolBar-toggle-min-qty"
                    id="pc-ToolBar-toggle-2"
                    type="checkbox"
                    aria-labelledby="pc-ToolBar-toggle-2-label"
                    checked={item.isRefined}
                    onChange={() => {}}
                    style={hideItem ? {display: 'none'} : {}}
                  />
                  <div
                    className="pc-ToolBar-toggle-button subcatNav2"
                    key={`${item.label}} toggle`}
                    data-insights-filter={`fs-order_qty:${item.isRefined}`}
                    onClick={(event) =>
                      minimumQuantityToggleClicked(event, item)
                    }
                    style={hideItem ? {display: 'none'} : {}}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </nav>
    );
  };

  const displayMinQtyToggle = () => {
    // setup conditions for rendering toggle
    if (
      validMinQtyColorsAvailable(results) &&
      items.some((item) => item.value === 'true')
    ) {
      return renderMinQtyToggleCrossSubcatNav();
    } else {
      return '';
    }
  };

  return displayMinQtyToggle();
};

export default MinimumQuantity;
