export const refinementListToSearchMap = {
    'colors.simple_names' : 'colors',
    'min_qty_colors.simple_names' : 'min_qty_colors',
    'fg-brand': 'brand',
    'has_singles_enabled_colors': 'min_qty',
    'min_order_qty': 'estimated_qty',
    'sort_by': 'sort_by'
  };

export const getCurrentFilterParams = (refinementItems) => {
    const currentFilterParams = []
    refinementItems?.map(refinementItem => {
      const refinementName = refinementItem.attribute.toString()
      refinementItem.refinements.map((refinement, index) => {
        currentFilterParams.push(`${refinementListToSearchMap[refinementName] || refinementName}[${index}]`)
      })
    })
    return currentFilterParams      
}

export const numericMenuEqual = (num1, num2) => {
  if (num1?.length && !num2?.length) return false;
  if ((num1.length === 0 && num2?.length === 0) || (!num1?.length && !num2?.length)) return true;
  if (!num1.length || num1.length !== num2?.length) return false;
  for (let i = 0; i < num1.length; i++) {
    if (num1[i].string !== num2[i]?.string ||
        num1[i].value !== num2[i]?.value ||
        num1[i].isRefined !== num2[i]?.isRefined
       ) {
      return false;
    }
  }
  return true;
}

export const refinementsEqual = (ref1, ref2) => {
  if (ref1?.length && !ref2?.length) return false;
  if (!ref1?.length && !ref2?.length && ref1?.length === ref2?.length) return true;
  if (!ref1.length || ref1.length !== ref2?.length) return false;
  for (let i = 0; i < ref1.length; i++) {
    if (ref1[i].indexName !== ref2[i].indexName ||
        ref1[i].attribute !== ref2[i].attribute ||
        ref1[i].refinements?.length !== ref2[i].refinements?.length
       ) {
      return false;
    }
    if (ref1[i].refinements.length) {
      if (!ref2[i].refinements?.length) return false;
      for (let j = 0; j < ref1[i].refinements.length; j++) {
        if (ref1[i].refinements[j].attribute !== ref2[i].refinements[j]?.attribute ||
            ref1[i].refinements[j].type !== ref2[i].refinements[j]?.type ||
            ref1[i].refinements[j].value !== ref2[i].refinements[j]?.value ||
            ref1[i].refinements[j].label !== ref2[i].refinements[j]?.label ||
            ref1[i].refinements[j]?.operator !== ref2[i].refinements[j]?.operator ||
            ref1[i].refinements[j]?.count !== ref2[i].refinements[j]?.count ||
            ref1[i].refinements[j]?.exhaustive !== ref2[i].refinements[j]?.exhaustive
           ) {
          return false;
        }
      }
    }
  }
  return true;
}
