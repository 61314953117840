import './index.scss';
import React, {useState} from 'react';
import {
  Typography,
  DialogLegacy,
  DialogLegacyContent,
  Link
} from '@customink/pigment-react';

const PriceDetailsDialog = ({
  onExitedHandler,
  onOpenHandler,
  priceDetails,
  title
}) => {
  const [open, setOpen] = useState(false);
  const handleClick = (event) => {
    event.preventDefault();
    if (!open) {
      onOpenHandler();
    }
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleExited = () => {
    onExitedHandler();
  };
  return (
    <>
      <DialogLegacy
        className="pc-Price-Details-Dialog"
        title={
          <Typography
            component={'div'}
            className="pc-Price-Details-Dialog-title">
            {title}
          </Typography>
        }
        open={open}
        onClose={handleClose}
        onTransitionExited={handleExited}>
        <DialogLegacyContent className="pc-Price-Details-Dialog-content">
          {priceDetails}
        </DialogLegacyContent>
      </DialogLegacy>
      <Link
        onClick={handleClick}
        button
        className="pc-Price-Details-Dialog-link">
        Pricing Details
      </Link>
    </>
  );
};

PriceDetailsDialog.defaultProps = {
  onExitedHandler: () => {},
  onOpenHandler: () => {},
};

export default PriceDetailsDialog;
