import React from 'react';
import { useSearchBox } from 'react-instantsearch';

const VirtualSearchBox = (props) => {
  useSearchBox(props);

  return null;
}

export default VirtualSearchBox;
