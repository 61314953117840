import React from 'react';
import { useNumericMenu } from 'react-instantsearch';

const VirtualNumericMenu = (props) => {
  useNumericMenu(props);

  return null;
}

export default VirtualNumericMenu;
