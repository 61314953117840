import { ALGOLIA_PERMITTED_NAV_PARAMS } from "./algolia_constants"

export const deleteQueryStringParams = (name) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.delete(name);
    const currentUrl = `${window.location.origin}${window.location.pathname}${params.size > 0 ? '?' : ''}${params.toString()}`;
    window.history.pushState({}, null, currentUrl);
}

export const setQueryStringParams = (name, value) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.set(name, value);
    const currentUrl = `${window.location.origin}${window.location.pathname}?${params.toString()}`;
    window.history.pushState({}, null, currentUrl);
}

export const checkAnyQueryParams = (...args) => {
    const currentParams = new URLSearchParams(window.location.search);
    return Array.from(args).some(item => currentParams.get(item.toString()));
}

export const getQueryParams = (routeState, categoryFilterKeys, currentSearchParams) =>  {
    let currentParams = {}
    if(currentSearchParams) {
      const currentSearchParamsIterator = new URLSearchParams(currentSearchParams)
      //convert url params to something like routeState
      for (const param of currentSearchParamsIterator) {
        const key = param[0]
        const value = param[1]
        //check if key is an array
        if(key.indexOf('[') > -1 && key.indexOf(']') > -1) {
          const currentParamsKey = key.substring(0, key.indexOf('['))
          if(currentParams[currentParamsKey]) {
            currentParams[currentParamsKey].push(value)
          } else {
            currentParams[currentParamsKey] = [value]
          }
        } else {
          currentParams[key] = value
        }
      }
    }
    //clean up url params based on routeState
    categoryFilterKeys.map(key => {
      if(routeState[key]) { //copy over value to currentParams
        if(currentParams[key]) {
          currentParams[key] = routeState[key]
        }
      } else { //remove from currentParams if it's in currentParams
        if(currentParams[key]) {
          delete currentParams[key]
        }
      }
    })
    //merge with other attributes in routeState
    return {...currentParams, ...routeState}
}

export const convertParamsToUrl = (urlQueryParams, algoliaUrl) => {
    let iterator = 0
    Object.keys(urlQueryParams).forEach((k) => {
      if(urlQueryParams[k]) {
        const element = urlQueryParams[k]
        if(Array.isArray(element)) {
          if(k === 'compatible_type') {
            if(iterator === 0) {
              algoliaUrl = algoliaUrl.concat(`?${k}=${element}`)
              iterator++
            } else {
              algoliaUrl = algoliaUrl.concat(`&${k}=${element}`)
            }
          } else {
            element.map((elementk, index) => {
              const key = encodeURIComponent(`${k}[]`);
              const encodedElement = encodeURIComponent(elementk)
              if(iterator === 0) {
                algoliaUrl = algoliaUrl.concat(`?${key}=${encodedElement}`)
                iterator++
              } else {
                algoliaUrl = algoliaUrl.concat(`&${key}=${encodedElement}`)
              }
            })
          }
        } else {
          const encodedElement = encodeURIComponent(element)
          if(iterator === 0) {
            algoliaUrl = algoliaUrl.concat(`?${k}=${encodedElement}`)
            iterator++
          } else {
            algoliaUrl = algoliaUrl.concat(`&${k}=${encodedElement}`)
          }
        }
      }
    })
    return algoliaUrl
}

export const navPath = (path, searchParams) => {
  let navSearchParams = new URLSearchParams();
  for (const [key, value] of searchParams.entries()) {
    if (ALGOLIA_PERMITTED_NAV_PARAMS.some(param => key.startsWith(param))) {
      navSearchParams.set(key, value);
    }
  }
  if (navSearchParams.toString()) {
    return `${path}?${navSearchParams.toString()}`
  }
  return path
}

export const percentReplacedElement = (element) => {
  let validElement = Array.isArray(element) ? [] : '';
  if (Array.isArray(element)) {
    element.map((ele) => {
      const arrayEle = String(ele) || '';
      if(arrayEle.includes('%')) {
        validElement.push(arrayEle.replace(/%(?![0-9a-fA-F]+)/g, '%25'));
      } else {
        validElement.push(arrayEle);
      }
    })
  } else {
    const strElement = String(element) || '';
    if (strElement.includes('%')) {
      validElement = strElement.replace(/%(?![0-9a-fA-F]+)/g, '%25');
    } else {
      validElement = strElement;
    }
  }
  return validElement;
}
