import React, { useState } from 'react';
import { useRefinementList } from 'react-instantsearch';

const VirtualRefinementList = (props) => {
  useRefinementList(props);

  return null;
}

export default VirtualRefinementList;
