import {useQuery} from 'react-query';

const fetchQuotes = async ({queryKey}) => {
  const [_, productId, quantities, fc, bc] = queryKey;
  const path = '/products/quotes/new';
  const quantitiesArray = Array.isArray(quantities) ? quantities : [quantities];
  const filteredQuantities = quantitiesArray.filter(
    (qty) => parseInt(qty, 10) > 0
  );

  if (productId && filteredQuantities.length > 0) {
    const quoteParams = encodeURI(
      filteredQuantities
        .map((qty, idx) => `quote[items[][quantity[${idx}]]]=${qty}`)
        .concat(
          `quote[items[][product_id]]=${productId}`,
          `quote[color_counts[front]]=${fc}`,
          `quote[color_counts[back]]=${bc}`,
          'quote[postal_code]=22031'
        )
        .join('&')
    );
    const response = await fetch(`${path}?${quoteParams}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.mapped;
  }
  return {};
};

const usePriceQuote = (
  productId,
  quantities = [],
  fc = 1,
  bc = 0,
  isEnabled = false
) => {
  return useQuery(
    ['fetch-quotes', productId, quantities, fc, bc],
    fetchQuotes,
    {
      enabled: isEnabled // Disable automatic execution on mount, and manually use 'refetch' to execute
    }
  );
};
export default usePriceQuote;
