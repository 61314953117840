import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRefinementList } from 'react-instantsearch';

const PriceLevel = (props) => {
  const { filterChangeHandler } = props;
  const algoliaProps = {...props};
  delete algoliaProps.filterChangeHandler;
  const { items, refine} = useRefinementList(algoliaProps);
  const labelForAttribute = `fs-price_level-`;

  const applyFilter = (event, item)  => {
    filterChangeHandler();
    window.dataLayer.push({
      event: 'catalog_filter',
      filter_type: 'fs-price_level',
      filter_value: item.value,
      filter_action: item.isRefined ? 'remove' : 'add'
    })
    refine(item.value);
  }

  return (
    <nav className='pc-FiltersGroup'>
        <header className={`pc-FiltersGroup-header ${!props.isCollapsedFilter && 'expanded'}`}>Price</header>
        <div className='pc-FiltersGroup-values'>
          {items?.map((item, index) => {
            return(
              <label
                htmlFor={labelForAttribute + item.highlighted}
                key={item.highlighted}
                className='sb-Form-checkbox pc-FiltersGroup-value'
                data-insights-filter={`${labelForAttribute}:${item.highlighted}`}
              >
                <input
                  type="checkbox"
                  id={labelForAttribute + item.highlighted}
                  value={item.highlighted}
                  checked={item.isRefined}
                  onChange={() => {}}
                  ></input>
                <a className='pc-FiltersGroup-link'
                onClick={(event) => applyFilter(event, item)}>
                  <span className="sb-Form-indicator"></span>
                  {`${Array(parseInt(item.value) + 1).join('$')} (${item.count})`}
                </a>
              </label>
            )
          })}
        </div>
    </nav>
  );
}

PriceLevel.propTypes = {
  attribute: PropTypes.string,
  limit: PropTypes.number
}

export default PriceLevel;
